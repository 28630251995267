<template lang="pug">
v-container
  alert(ref="alert")
  div.totalDebt
    p.mr-5.pt-5.subtitle(style="color: #fff") Efectivo total por depositar
    h1.ml-8(style="color: #fff") {{ totalToPay | currency }}
  br
  v-data-table(
    :headers="headers" 
    class="elevation-0" 
    :items="withdrawals" 
    hide-default-footer 
    :loading="table.loading"
    no-data-text="No hay Movimientos"
    loading-text="Cargando Movimientos..."
  )
    template(v-slot:item.created_at="{ item }")
      p {{ formatDate(item.created_at) }} hrs.
    template(v-slot:item.state="{ item }")
      v-chip(label :color="getColor(item.state)" :text-color="getColorText(item.state)" dark) {{ item.state }}
    template(v-slot:item.credit_term="{ item }")
      p(v-if="!Number(item.credit_term)") {{ item.credit_term ? item.credit_term?.split('-').reverse().join('/') : '' }}
    template(v-slot:item.payment_total="{ item }")
      p {{ item.payment_total | currency }}
    template(v-slot:item.limit_date="{ item }")
      p {{ formatDateDayShort(item.limit_date) }}
    template(v-slot:item.description="{ item }")
      p {{ item.description }} <br/> {{ item.check_number && `Cheque ${item.check_qty} - N° ${item.check_number}` }}
    template(v-slot:item.actions="{ item }")
      v-btn(style="background-color: #E1EAFF; color: #407BFF" elevation="0" @click="handleOpenConfirmPayment(item)")
        v-icon.mr-2(style="color: #407BFF" dense) mdi-cash-100
        span Depositar
  v-pagination.mt-4(v-model="page" :length="pageCount" circle color="primary" elevation=0)

  v-dialog(v-model="confirmPayment" transition="dialog-top-transition" width="450" content-class="elevation-0" style="border-radius: 16px;")
    v-card(style="padding: 50px 20px 20px 20px;")
      div.payment-img
      v-card-title.justify-center(style="color: #4C7AEC;" v-if="selectedPendingPayment.payment_total") ¿Seguro quieres depositar {{ selectedPendingPayment.payment_total | currency }}?
      small.d-flex.justify-center.mb-2 Sube un comprobante
      v-file-input(v-model="file" color="primary" label="+ Añadir foto" prepend-icon="mdi-paperclip" accept="image/png, image/jpg, image/jpeg, image/tiff" outlined )
        template(v-slot:selection="{ text }")
          v-chip( color="primary" label small) {{ text }}
      v-card-actions
        v-row
          v-col(cols="6")
            v-btn(@click="confirmPayment = false" block elevation="0" color="#4C7AEC" outlined) Cancelar
          v-col(cols="6")
            v-btn.mr-5(color="#4C7AEC" :dark="file !== null" @click="handlePay()" :disabled="file === null" block) Confirmar
</template>
<script>
import { mapActions } from 'vuex'
import alert from '../shared/alert.vue'
export default {
  props: {
    tab: {
      type: Number,
      default: 4
    }
  },
  components: {
    alert
  },
  data () {
    return {
      page: 1,
      pageCount: 0,
      headers: [
        { text: 'Fecha y hora', value: 'created_at' },
        { text: 'Descripción', value: 'description' , width: '33%' },
        { text: 'Monto', value: 'payment_total' },
        { text: 'Acciones', value: 'actions' }
      ],
      table: {
        loading: false
      },
      withdrawals: [],
      totalToPay: 0,
      confirmPayment: false,
      selectedPendingPayment: {},
      file: null,
    }
  },
  methods: {
    ...mapActions('inventory', ['listCashWithdrawals', 'payExpenseWithReceipt', 'createExpense']),
    formatDate (date) {
      return new Intl.DateTimeFormat('cl-ES', { day: '2-digit', month: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric' }).format(new Date(date))
    },
    formatDateDay (date) {
      return new Intl.DateTimeFormat('cl-ES', { dateStyle: 'long' }).format(new Date(date))
    },
    formatDateDayShort (date) {
      return new Intl.DateTimeFormat('cl-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(date))
    },
    getColor (type) {
      return type === 'Pendiente' ? '#F19292' : '#FFD6EC'
    },
    getColorText (type) {
      return type === 'Pendiente' ? '#820000' : '#A71A5E'
    },
    fetchData () {
      this.table.loading = true
      this.listCashWithdrawals({ params: { page: this.page }}).then(
        response => {
          if (response.status === 200) {
            this.withdrawals = response.data.results.filter(w => w.state !== 'Depositado' )
            this.pageCount = Math.ceil(response.data.count / 10)
            this.totalToPay = response.data.pending_cash_withdrawals
          } else {
            this.withdrawals = []
            this.totalToPay = 0
            this.pageCount = 0
          }
        }
      )
      this.table.loading = false
    },
    handleOpenConfirmPayment (item) {
      this.file = null
      this.confirmPayment = !this.confirmPayment
      this.selectedPendingPayment = item
    },
    handlePay () {
      const formdata = new FormData()
      formdata.append('receipt', this.file)
      formdata.append('description', '')
      formdata.append('state', 'Depositado')
      const bankDeposit = {
        state: 'Pagado',
        description: `Depósito bancario por concepto de: ${this.selectedPendingPayment.description}`,
        payment_total: this.selectedPendingPayment.payment_total,
        payment_gateway: 'Efectivo',
        movement_type: 'Depósito bancario',
        linked_expense_id: this.selectedPendingPayment.id
      }
      
      this.payExpenseWithReceipt({ data: formdata, id: this.selectedPendingPayment.id }).then(
        response => {
          if (response.status === 200) {
            this.createExpense({ data: { ...bankDeposit } }).then((response) => {
            })
            this.confirmPayment = false
            this.fetchData()
            this.$refs.alert.handleShowAlert('success', 'Depósito realizado con éxito', true, 'green')
          }
          if (response.status === 413) {
            this.$refs.alert.handleShowAlert('error', 'Imagen supera el límite de tamaño permitido', true, 'red')
          }
        } 
      )
    },
    getTotalCashToDeposit() {
      const totalToPay = this.withdrawals.reduce((acc, curr) => acc + curr.payment_total, 0)
      return totalToPay
    }
  },
  created () {
    this.fetchData()
  },
  watch: {
    tab (newVal) {
      if (newVal === 4) {
        this.fetchData()
      }
    },
    page () {
      this.fetchData()
    }
  }
}
</script>
<style lang="scss" scoped>
.totalDebt {
  height: 100px;
  background-image: url('./../../assets/img/report.png');
  background-size: cover;
  background-position: center;
  border-radius: 16px;
}

.subtitle {
  font-size: 16px;
  margin-left: 32px;
}
.v-application p {
  margin-bottom: 0;
}
.payment-img {
  height: 160px;
  background-image: url('./../../assets/img/money.png');
  background-size: contain;
  background-position: center;
}
</style>
