<template lang="pug">
div.d-flex.flex-column
  v-row.justify-space-between
    .mt-1.ml-3.primary--text(style="font-weight: 600;") Repuestos
    v-btn.mb-3.mr-9(v-if="!disabled" text color="primary" @click="addPiece") + Agregar Items
  v-row(v-for="(item, index) in saleItems" :key="index" dense)
    v-col
      v-autocomplete(
          v-if="item.new_piece_name == null"
          :search-input.sync="item.search"
          v-model="item.piece"
          item-value="id"
          item-text="name"
          :items="item.piece ? [...filteredPieces, item.piece] : filteredPieces "
          outlined
          return-object
          label="Repuesto"
          :rules="[v => !!v]"
          :disabled="disabled"
        )
        template(v-slot:item="data")
          p {{data.item.name}}
            v-chip.compatible(v-if="data.item.isCompatible > 0" color="success" outlined small) compatible
            span.stock {{ data.item.stock > 0 ? `Stock: ${data.item.stock}` : 'Sin stock' }}
        template(#append-item)
          div.mr-2.ml-2
            v-btn(
              color="primary" block
              @click="convertToNewPiece(item, index)"
              :disabled="false"
            ) Agregar como repuesto nuevo
      v-text-field(v-if="item.new_piece_name != null" v-model="item.new_piece_name" outlined :rules="[v => !!v]" label="Repuesto")
        template(#append)
          div.mr-2.ml-2
            v-chip.compatible(color="primary" outlined small pill) nuevo
    v-col(cols=2)
      v-text-field#unit-price(v-if="item.piece == null" v-model="item.unit_price" outlined v-maska:[moneyMaskFormat] prefix="$" label="Precio")
      v-text-field#price(v-if="item.piece != null" v-model="item.piece.price" outlined v-maska:[moneyMaskFormat] prefix="$" label="Precio")
    v-col(cols=1)
      v-text-field(v-model="item.quantity" outlined :rules="getQuantityValidations(item)" type="number" min="1" label="Cantidad")
    v-col(cols=2)
      v-text-field(:value="getTotal(item)" outlined v-maska:[moneyMaskFormat] prefix="$" label="Total" disabled)
    v-col(v-if="!disabled" cols="auto")
      v-btn.mt-3(fab color="white" x-small depressed @click="saleItems.splice(index, 1)")
        v-icon mdi-close-thick

  CreateNewPiecesModal(
    v-model="showCreateNewPiecesModal"
    :itemsToCreate="saleItemsToCreate"
    @close="showCreateNewPiecesModal = false"
    @created="handlePiecesCreated"
  )
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { vMaska } from 'maska'
import CreateNewPiecesModal from './CreateNewPiecesModal.vue'
import { moneyMaskFormat } from '@/utils/mask'

export default {
  components: {
    CreateNewPiecesModal,
  },
  directives: { maska: vMaska },
  props: {
    value: {
      type: Array[Object],
      default: () => [{ price: 0, quantity: 1, total_price: 0 }]
    },
    disabled: Boolean,
    vehicle: {
      type: Object
    },
    isQuotation: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      saleItems: [],
      showCreateNewPiecesModal: false,
      moneyMaskFormat
    }
  },
  methods: {
    ...mapActions('inventory', ['listPieces']),

    addPiece() {
      this.saleItems.push({
        piece: null,
        price: 0,
        quantity: 1,
        total_price: 0,
      })
    },

    getQuantityValidations (item) {
      return [
        v => v > 0,
        v => item.piece && v > item.piece.stock ? `Sin stock (Stock: ${item.piece.stock})` : true
      ]
    },

    init() {
      this.listPieces()
      this.$emit('totalPrice', this.totalPrice)
    },

    convertToNewPiece (item, index) {
      const newItem = {
        ...item,
        new_piece_name: item?.search || item?.piece?.name || '',
        price: Number(item.unit_price),
        total_price: item.total_price,
        isnew: true,
      }
      this.$set(this.saleItems, index, newItem)
    },

    handlePiecesCreated (pieces) {
      const newItems = this.saleItems.filter(el => el.new_piece_name != null)
      this.saleItems.push(...pieces.map((piece, index) => {
        return {
          piece: piece,
          quantity: Number(newItems[index].quantity),
          price: Number(newItems[index].unit_price),
        }
      }))
      this.saleItems = this.saleItems.filter(el => el.new_piece_name == null)
    },
    getTotal(item) {
      if (item.piece == null) {
        let unitPrice = item.unit_price
        if (typeof item.unit_price === 'string') unitPrice = parseInt(item.unit_price.split('.').join(''))
        return unitPrice * item.quantity
      }
      let price = item.piece.price
      if (typeof item.piece.price === 'string') price = parseInt(item.piece.price.split('.').join(''))
      return price * item.quantity
    }
  },
  computed: {
    ...mapGetters('inventory', ['pieces']),
    filteredPieces () {
      const piecesInUse = this.saleItems.filter(el => el.piece).map(el => el.piece)

      const filteredPieces = this.pieces.filter(piece => !piecesInUse.find(el => el.id === piece.id))
      if (this.vehicle) {
        return filteredPieces.map(piece => {
          return {
            ...piece,
            isCompatible: piece.compatible_models.includes(this.vehicle.model),
            price: piece.piece? piece.piece.price : piece.price || piece.unit_price
          }
        }).sort((a, b) => b.isCompatible - a.isCompatible)
      }
      return filteredPieces
    },
    totalPrice () {
      return this.saleItems.filter(el => el.piece || el.new_piece_name)
        .reduce((acum, el) => acum + el.total_price, 0)
    },
    saleItemsToCreate () {
      return this.saleItems.filter(el => el.new_piece_name != null)
    },
  },
  watch: {
    saleItems: {
      deep: true,
      handler () {
        // Atualiza el precio total
        this.saleItems.map(el => {
          const price = el.new_piece_name ? el.unit_price : (el.price ?? el.unit_price ?? el.piece.price)
          el.total_price = price ? price * el.quantity : 0
          el.quantity = parseInt(el.quantity) < 1 ? 1 : el.quantity
          el.unit_price = price
          el.price = price
          return el
        })
        this.$emit('input', this.saleItems)
        this.$emit('change', this.saleItems)
        this.$emit('totalPrice', this.totalPrice)
      }
    },
    saleItemsToCreate: {
      deep: true,
      handler (val) {
        if (val.length > 0) {
          this.$emit('updatePieceCreationPending', true)
        } else {
          this.$emit('updatePieceCreationPending', false)
        }
      }
    },
    value: {
      deep: true,
      immediate: true,
      handler (newValue) {
        if (newValue !== this.saleItems) {
          this.saleItems = [...newValue]
        }
      }
    }
  },
  created () {
    this.init()
  },
}
</script>
<style lang="scss" scoped>
.compatible {
  margin-left: 5px;
  font-size: 12px;
}
.stock {
  margin-left: 5px;
  color: gray;
  font-size: 12px;
}
.disable_text {
  color: rgba(0, 0, 0, 0.12);
}

.new-pieces-alert {
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  border: 1px solid #8E8E8E;
  border-radius: 4px;
  padding: 8px 12px;
}

.new-pieces-list {
  margin-left: 30px;
}

.btn-add-piece {
  padding: 0 !important;
  height: auto !important;
  text-decoration: underline;
}

</style>
