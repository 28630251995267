<template lang="pug">
div
  v-card(v-if="ot" tile color="white")
    div.flex
      v-icon.ma-4(style="color: #1A1796;" dark @click="$router.back()") mdi-arrow-left
    v-chip.ml-4(label text-color="#6558DA" :color="getStatusColor(estimate.state)") Estado: {{estimate.state}}
    v-card-title
      span.title Diagnóstico Presupuesto n°{{estimate.id}}

    v-expansion-panels(flat)
      v-expansion-panel
        v-expansion-panel-header.category Detalles del auto
        v-expansion-panel-content
          VehicleDetailBox(:vehicle="ot.vehicle_data")

    v-expansion-panels(flat)
      v-expansion-panel
        v-expansion-panel-header.category
          v-row(no-gutters)
            v-col(cols="6")
              p Kilometraje vehículo
            v-col.red--text(cols="6")
              span(v-if="vehicle_milage === null || vehicle_milage === ''" style="font-size: 12px;") Kilometraje no ingresado
        v-expansion-panel-content.mt-n4
          v-row(v-if="!editVehicleMilage")
            v-col(cols="8")
              v-text-field.mt-3(outlined dense type="number" style="width: 100%;" placeholder="Ingresa el kilometraje" v-model="vehicle_milage")
            v-col(cols="4")
              v-btn.mt-3(color="primary" outlined :disabled="vehicle_milage === null || vehicle_milage === ''" @click="handleRegisterMilage") Guardar
          v-row(v-else)
            v-col(cols="10")
              div.client-comments {{ vehicle_milage }} Kms
            v-col(cols="2")
              v-btn.mt-2(icon style="color: #171993" :disabled="vehicle_milage === null || vehicle_milage === ''" @click="editVehicleMilage = false")
                v-icon mdi-square-edit-outline

    v-card-title.pt-0.pb-4.pl-6.category Comentarios del cliente
    v-card-text.primary--text(style="padding-bottom: 0 !important;")
      div.client-comments {{estimate.client_comments || 'Sin comentarios'}}

    v-expansion-panels(flat v-model="panel")
      v-expansion-panel
        v-expansion-panel-header.category Trabajos predefinidos ({{estimate.estimate_services.length}})
        v-expansion-panel-content
          EstimateServiceList(:estimate="estimate")
      v-expansion-panel
        v-expansion-panel-header.category Insumos Adicionales
        v-expansion-panel-content
          div
            AdditionalItems(v-if="!loading" :ot="ot" @showAlert="handleShowAlert")

      v-expansion-panel
        v-expansion-panel-header.category Recomendaciones
        v-expansion-panel-content
          v-textarea(outlined background-color="#F6F5FD" v-model="recommendation" clearable counter :rules="rules" auto-grow rows="3" row-height="25")
          v-btn.mb-2(block color="primary" @click="saveRecommendation" :loading="savingRecom" :disabled="recommendation === ''") Guardar
          v-card(v-for="r in recommendations" elevation="0" outlined :disabled="disabledDifferentTech(r)")
            v-row
              v-col(cols="8")
                v-card-subtitle.py-0.mt-3 Creado: {{ formatDate(r.date) }}
                p.ml-4 {{ r.text }}
              v-col(cols="2")
                v-avatar.mt-3(color="#FFD699" size=32 dark) {{getAvatarText(r.added_by)}}
              v-col(cols="2")
                v-btn.mt-3.mr-1(icon color="primary" @click="editRecommendation(r)")
                  v-icon mdi-pencil

    v-card-title.pl-6.pb-4.category Diagnóstico
    v-card-text.px-6
      v-form(ref="form" @submit.prevent="onSubmit(true)" :disabled="loading")
        v-textarea.comment(v-model="form.evaluation_comment" outlined background-color="#FFF4E1" color="#EA5C0B" :disabled="estimate.state != 'En diagnóstico'" :rules="evaluationCommentRules")

        div(v-if="form.photos.length" style="overflow-x: auto; display: flex; position: relative; ")
          v-card.mr-2(v-for="(photo, index) in form.photos" height="173px" width="173px")
            v-img.white--text.align-end(style="margin: 0 4px 0 0 !important;" height="173px" width="173px" :src="photo.preview" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)")
              v-card-text {{photo.comment}}
              v-btn.close-btn(v-if="estimate.state == 'En diagnóstico'" icon @click="removeImg(index)" dark)
                v-icon mdi-close
        v-file-input.white(v-if="estimate.state == 'En diagnóstico'" ref="imgInput" accept="image/*" label="Adjuntar foto" solo flat hide-details @change="addImg")

        v-row.mt-4(v-if="estimate.state == 'En diagnóstico'" dense)
          v-col(cols=12)
            v-btn(block color="primary" x-large type="submit" :loading="loading") Terminar
          v-col(cols=12)
            v-btn(block color="primary" outlined x-large style="background-color: white;" @click="onSubmit(false)" :loading="loading") Guardar borrador

  .d-flex.align-center.justify-center(v-else)
    v-progress-circular(indeterminate color="primary" size="64")

  ImgCommentInput(ref="imgCommentInput" @save="form.photos.push($event)")
  alert(ref="alert")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VehicleDetailBox from '../vehicle/VehicleDetailBox.vue'
import EstimateServiceList from './WorkInProgress/EstimateServiceList.vue'
import ImgCommentInput from './subcomponent/ImgCommentInput.vue'
import AdditionalItems from './subcomponent/AdditionalItems.vue'
import StateColorMixin from '../mixins/StateColorMixin.vue'
import alert from '../shared/alert.vue'
export default {
  mixins: [StateColorMixin],
  components: {
    VehicleDetailBox,
    ImgCommentInput,
    EstimateServiceList,
    alert,
    AdditionalItems,
  },
  data() {
    return {
      panel: -1,
      pieces: [],
      ot: null,
      estimate: null,
      estimateId: null,
      isOpen: false,
      loading: false,
      recommendation: '',
      recommendations: [],
      selectedRecommendation: {},
      rules: [(v) => v.length <= 100 || 'Máx 100 caracteres'],
      evaluationCommentRules: [(v) => !!v || 'Diagnóstico requerido'],
      isEditing: false,
      form: {
        evaluation_comment: null,
        photos: [],
      },
      vehicle_milage: null,
      editVehicleMilage: false,
      savingRecom: false,
    }
  },
  methods: {
    ...mapActions('inventory', [
      'listPieces',
      'getVehicleMilage',
      'createVehicleMilage',
      'getRecommendations',
      'createRecommendation',
      'updateRecommendation',
    ]),
    ...mapActions('ot', [
      'getOT',
      'setOTEvaluation',
      'listEvaluation',
      'listJobs',
      'listJobsOrder',
    ]),
    ...mapActions('estimate', ['getEstimate', 'setEstimateEvaluation']),
    removeImg(index) {
      this.form.photos.splice(index, 1)
    },
    addImg(img) {
      if (img) {
        this.$refs.imgCommentInput.open({ img })
        this.$refs.imgInput.value = undefined
      }
    },
    async fetchData(isReloading = false) {
      this.loading = true
      this.recommendation = ''
      this.ot = null
      const estimateResponse = await this.getEstimate({
        estimateId: this.estimateId,
      })
      const otResponse = await this.getOT({
        OTId: estimateResponse.data.work_order,
      })
      if (otResponse.status === 200) {
        this.ot = otResponse.data
        this.getAddicionalItems()
        this.estimate = estimateResponse.data
        if (!isReloading) {
          this.form.evaluation_comment =
            this.estimate.evaluation.evaluation_comment
        }
        this.form.photos = this.estimate.evaluation.images.map((el) => ({
          id: el.id,
          preview: el.image_file,
          img: el.image_file,
          comment: el.image_description,
        }))
        this.getVehicleMilage({ id: this.ot.id }).then((response) => {
          if (
            response.status === 200 &&
            response.data.vehicle_milage !== null
          ) {
            this.vehicle_milage = this.numberWithDots(
              response.data.vehicle_milage
            )
            this.editVehicleMilage = response.data.vehicle_milage !== null
          }
        })
        this.getRecommendations({ id: this.ot.id }).then((response) => {
          this.recommendations = response.data
        })
      }
      this.loading = false
    },
    open(estimateId) {
      this.estimateId = estimateId
      this.fetchData()
      this.isOpen = true
    },
    showAlert(data) {
      this.$refs.alert.handleShowAlert(
        data.type,
        data.message,
        data.show,
        data.color
      )
    },
    async onSubmit(nextState = true) {
      if (!this.$refs.form.validate()) {
        return
      }

      const formData = new FormData()
      formData.append('evaluation_comment', this.form.evaluation_comment)
      if (nextState) {
        formData.append('next_state', nextState)
      }

      for (const photo of this.form.photos) {
        if (typeof photo.img !== 'string') {
          formData.append('image_file', photo.img)
          formData.append('image_description', photo.comment)
        }
        console.log(photo)
        if (photo.id) {
          formData.append('image_id', photo.id)
        }
      }

      this.loading = true
      const response = await this.setEstimateEvaluation({
        estimateId: this.estimate.id,
        data: formData,
      })

      if (response.status === 200) {
        this.showAlert({
          type: 'success',
          message: 'Diagnóstico guardado con éxito',
          show: true,
          color: 'green',
        })
        setTimeout(() => this.$router.back(), 2000)
        await this.listEvaluation()
        await this.listJobs()
        await this.listJobsOrder({ params: { is_job_to_repair: true } })
        if (!nextState) {
          this.showAlert({
            type: 'success',
            message: 'Diagnóstico guardado como borrador',
            show: true,
            color: 'green',
          })
          setTimeout(() => this.$router.back(), 2000)
        }
        this.$emit('save', response.data)
        this.isOpen = false
      }
      this.loading = false
    },
    async saveRecommendation() {
      if (this.recommendation !== '') {
        this.savingRecom = true
        const recommendationData = {
          text: this.recommendation,
          work_order: this.ot.id,
        }
        if (this.isEditing) {
          await this.updateRecommendation({
            id: this.selectedRecommendation.id,
            data: recommendationData,
          }).then((response) => {
            if (response.status >= 200 && response.status < 300) {
              this.showAlert({
                type: 'sucess',
                message: 'Recomendación actualizada con éxito',
                show: true,
                color: 'green',
              })
            }
          })
        } else {
          await this.createRecommendation({ data: recommendationData }).then(
            (response) => {
              if (response.status >= 200 && response.status < 300) {
                this.showAlert({
                  type: 'sucess',
                  message: 'Recomendación creada con éxito',
                  show: true,
                  color: 'green',
                })
              }
            }
          )
        }
        this.savingRecom = false
        this.fetchData(true)
      }
    },
    editRecommendation(recommendation) {
      this.selectedRecommendation = recommendation
      this.recommendation = recommendation.text
      this.isEditing = true
    },
    getAddicionalItems() {
      this.listPieces().then((response) => {
        this.pieces = response.data.map((p) => {
          const piece = this.ot.pieces.filter((q) => q.piece === p.id)
          return {
            id: p.id,
            name: p.name,
            quantity: piece.length > 0 ? piece[0].quantity : 1,
          }
        })
      })
    },
    disabledDifferentTech(recommendation) {
      if (this.user) {
        return recommendation.added_by.username !== this.user.username
      }
      return false
    },
    handleShowAlert(data) {
      this.panel = -1
      window.scrollTo(1000, 0)
      this.fetchData(true)
      this.showAlert(data)
    },
    handleRegisterMilage() {
      const vehicleMilage = +this.vehicle_milage.split('.').join('')
      this.createVehicleMilage({
        id: this.ot.id,
        data: { vehicle_milage: vehicleMilage },
      }).then((response) => {
        if (response.status === 200)
          this.handleShowAlert({
            type: 'success',
            message: 'Kilometraje guardado exitosamente',
            show: true,
            color: 'green',
          })
      })
    },
    numberWithDots(n) {
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    formatDate(date) {
      return new Intl.DateTimeFormat('cl-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }).format(new Date(date))
    },
    getAvatarText(tech) {
      return tech.first_name.charAt(0) + tech.last_name.charAt(0)
    },
  },
  computed: {
    ...mapGetters('user', ['technicians']),
    ...mapGetters('auth', ['user']),
  },
}
</script>

<style lang="scss" scoped>
.back-button {
  width: 100px;
}
.title {
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 28px !important;
  color: #1a1796;
}
.category {
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  color: #2657c1;
}
.close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
}

.client-comments {
  padding: 16px;
  background-color: #f6f5fd;
  font-size: 16px;
  color: #2657c1;
}

.comment::v-deep textarea {
  color: #ea5c0b !important;
}

</style>
<style>
.v-expansion-panel-content__wrap {
  padding: 0 5px !important;
}
</style>
