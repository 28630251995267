// import { mapActions } from 'vuex'
import axios from '../../plugins/axios'

export const listOT = ({ commit }, { params } = {}) => {
  return axios.get('inventory/work_order/', { params })
    .then(res => {
      commit('SET_OTS', res.data.results)
      return res
    })
    .catch(err => err.response)
}

export const searchOTsByID = (_ , { params } = {}) => {
  return axios.get('inventory/search_ots', { params })
    .then(res => res)
    .catch(err => err.response)
}

export const getOT = ({ commit }, { OTId, params } = {}) => {
  return axios.get(`inventory/work_order/${OTId}/`, { params })
    .then(res => {
      commit('SET_OT', res.data)
      return res
    })
    .catch(err => err.response)
}

export const getOTByToken = ({ commit }, { params } = {}) => {
  return axios.get('inventory/work_order/get_by_token/', { params })
    .then(res => {
      commit('SET_OT', res.data)
      return res
    })
    .catch(err => err.response)
}

export const createOT = (_, { data } = {}) => {
  return axios.post('inventory/work_order/', data)
    .catch(err => err.response)
}

export const updateOT = (_, { OTId, data } = {}) => {
  return axios.patch(`inventory/work_order/${OTId}/`, data)
    .catch(err => err.response)
}

export const setOTEvaluation = (_, { OTId, data } = {}) => {
  return axios.put(`inventory/work_order/${OTId}/set_evaluation/`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
    .catch(err => err.response)
}

export const setOTEvaluationTech = (_, { OTId, data } = {}) => {
  return axios.put(`inventory/work_order/${OTId}/set_evaluation_tech/`, data)
    .catch(err => err.response)
}

export const setOTServiceTech = (_, { OTServiceId, data } = {}) => {
  return axios.put(`inventory/order_service/${OTServiceId}/set_services_tech/`, data)
    .catch(err => err.response)
}

// Peneiente por eliminar al reemplazar estimate_services por order_services
export const setOTServiceTech2 = (_, { OTServiceId, data } = {}) => {
  return axios.put(`inventory/estimate_services/${OTServiceId}/set_services_tech/`, data)
    .catch(err => err.response)
}

export const setOTBudget = (_, { OTId, data } = {}) => {
  return axios.put(`inventory/work_order/${OTId}/set_budget/`, data)
    .catch(err => err.response)
}

export const listBudgets = ({ commit }, otID) => {
  return axios.get(`inventory/work_order/${otID}/estimate_aproval`)
    .then(res => {
      commit('SET_BUDGET', res.data)
      return res
    })
    .catch(err => err.response)
}

export const listEvaluation = ({ commit }, { params } = {}) => {
  return axios.get('inventory/evaluation_list/?estado_presupuesto=En%20diagn%C3%B3stico', { params })
    .then(res => {
      commit('SET_EVALUATIONS', res.data.results)
      commit('SET_EVALUATIONS_COUNT', res.data.count)
      return res
    })
    .catch(err => err.response)
}

export const listJobs = ({ commit }, { params } = {}) => {
  return axios.get('inventory/job_list/?estimate__state=En%20reparaci%C3%B3n', { params })
    .then(res => {
      commit('SET_JOBS', res.data.results)
      commit('SET_JOBS_COUNT', res.data.count)
      return res
    })
    .catch(err => err.response)
}

export const listJobsOrder = ({ commit }, { params } = {}) => {
  return axios.get('inventory/job_order_list/', { params })
    .then(res => {
      commit('SET_JOBS', res.data.results)
      commit('SET_JOBS_COUNT', res.data.count)
      return res
    })
    .catch(err => err.response)
}

export const listDiagnosed = ({ commit }, { params } = {}) => {
  return axios.get('inventory/evaluation_list/?estado_presupuesto=Diagnosticado', { params })
    .then(res => {
      commit('SET_DIAGNOSED', res.data.results)
      commit('SET_DIAGNOSED_COUNT', res.data.count)
      return res
    })
    .catch(err => err.response)
}

const REPAIRED_STATES = ['Reparado', 'Listo', 'Entregado'].join(',')

export const listJobsRepaired = ({ commit }, { params } = {}) => {
  return axios.get(`inventory/job_list/?estimate__state__in=${REPAIRED_STATES}`, { params })
    .then(res => {
      commit('SET_REPAIRED', res.data.results)
      commit('SET_REPAIRED_COUNT', res.data.count)
      return res
    })
    .catch(err => err.response)
}

export const listJobsOrderRepaired = ({ commit }, { params } = {}) => {
  return axios.get('inventory/job_order_list/', { params })
    .then(res => {
      commit('SET_REPAIRED', res.data.results)
      commit('SET_REPAIRED_COUNT', res.data.count)
      return res
    })
    .catch(err => err.response)
}

export const listHistoricalJobsRepaired = (_, { params } = {}) => {
  return axios.get(`inventory/job_list/?estimate__state__in=${REPAIRED_STATES}`, { params })
    .then(res => {
      return res
    })
    .catch(err => err.response)
}

export const listHistoricalJobsOrderRepaired = (_, { params } = {}) => {
  return axios.get(`inventory/job_order_list/`, { params })
    .then(res => {
      return res
    })
    .catch(err => err.response)
}

export const listServices = ({ commit }, { OTId } = {}) => {
  return axios.get(`inventory/work_order/${OTId}/get_order_services`)
    .then(res => {
      commit('SET_JOBS', res.data)
      return res
    })
    .catch(err => err.response)
}

// Pendiente por eliminar al reemplazar estimate_services por order_services
export const listServices2 = ({ commit }, { OTId } = {}) => {
  return axios.get(`inventory/work_order/${OTId}/get_estimate_services`)
    .then(res => {
      commit('SET_JOBS', res.data)
      return res
    })
    .catch(err => err.response)
}

export const aproveOrPay = (_, { data } = {}) => {
  return axios.post('inventory/manual_aproval', data)
    .catch(err => err.response)
}

export const manualPayment = (_, { data } = {}) => {
  return axios.post('inventory/manual_payment', data)
    .catch(err => err.response)
}

export const servicesForAproval = ({ commit }, { otID } = {}) => {
  return axios.get(`inventory/work_order/${otID}/jobs_for_aproval/`)
    .then(res => {
      commit('SET_JOBS', res.data)
      return res
    })
    .catch(err => err.response)
}

export const listOTPayments = ({ commit }, { OTId } = {}) => {
  return axios.get(`inventory/work_order/${OTId}/get_payments`)
    .then(res => {
      commit('SET_PAYMENTS', res.data)
      return res
    })
    .catch(err => err.response)
}

export const aproveJob = (_, { data } = {}) => {
  return axios.post('inventory/job_aproval', data)
    .catch(err => err.response)
}

export const aproveJobs = (_, { data } = {}) => {
  return axios.post('inventory/jobs_aproval', data)
    .catch(err => err.response)
}

export const approvedPayments = (_, { otID } = {}) => {
  return axios.get(`inventory/work_order/${otID}/get_payments/`)
    .then(res => {
      return res
    })
    .catch(err => err.response)
}

export const listOTTransitions = ({ commit }, { OTId } = {}) => {
  return axios.get(`inventory/work_order/${OTId}/get_transitions`)
    .then(res => {
      commit('SET_TRANSITIONS', res.data)
      return res
    })
    .catch(err => err.response)
}

export const returnCar = (_, { data } = {}) => {
  return axios.post('inventory/return_car', data)
    .catch(err => err.response)
}

export const addItems = (_, { data, id } = {}) => {
  return axios.post(`inventory/work_order/${id}/add_items/`, data)
    .catch(err => err.response)
}

export const updateItems = (_, { id, data } = {}) => {
  return axios.put(`inventory/work_order_pieces/${id}/`, data)
    .catch(err => err.response)
}

export const createTemplate = (_, { data } = {}) => {
  return axios.post('inventory/estimate_templates/', data)
    .catch(err => err.response)
}

export const getTemplates = ({ commit }, { params } = {}) => {
  return axios.get('inventory/estimate_templates/', { params })
    .then(res => {
      commit('SET_TEMPLATES', res.data.results)
      return res
    })
    .catch(err => err.response)
}

export const getExpensesByOT = (_, { OTId } = {}) => {
  return axios.get(`inventory/work_order/${OTId}/get_expenses`)
    .then(res => {
      return res
    })
    .catch(err => err.response)
}

export const getQuotation = (_, { quotationId, params } = {}) => {
  return axios.get(`inventory/quote/${quotationId}/`, { params })
    .catch(err => err.response)
}

export const listQuotation = (_, { params } = {}) => {
  return axios.get('inventory/quote/', { params })
    .catch(err => err.response)
}

export const createQuotation = (_, { data } = {}) => {
  return axios.post('inventory/quote/', data)
    .catch(err => err.response)
}

export const updateQuotation = (_, { id, data } = {}) => {
  return axios.put(`inventory/quote/${id}/`, data)
    .catch(err => err.response)
}
