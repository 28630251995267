import { render, staticRenderFns } from "./NewServiceModal.vue?vue&type=template&id=9c88c18a&scoped=true&lang=pug"
import script from "./NewServiceModal.vue?vue&type=script&lang=js"
export * from "./NewServiceModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c88c18a",
  null
  
)

export default component.exports