<template lang="pug">
v-data-table(
  :headers="headers"
  :options.sync="tableOptions"
  :items="results"
  :server-items-length="totalItems"
  :loading="loading"
  hide-default-footer
  loading-text="Cargando datos..."
)
  template(#item.created_by="{ item }")
    span {{ item.created_by ? (item.created_by.full_name === ' ' ? '-' : item.created_by.full_name) : '-' }}
  template(#item.customer_pending_debt="{ value }")
    span {{ value | currency }}
  template(#item._actions="{ item }")
    v-btn.action-btn(text @click="selectItem(item)")
      v-icon.mr-1 mdi mdi-eye
</template>

<script>
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useCustomerListStore } from '../../../stores/views/useCustomerListStore'

export default {
  computed: {
    ...mapState(useCustomerListStore, ['results', 'totalItems', 'loading']),
    ...mapWritableState(useCustomerListStore, [
      'tableOptions',
      'showCustomerDetailModal',
      'selectedCustomer',
    ]),

    headers() {
      return [
        { text: 'ID', value: 'id' },
        { text: 'Nombre', value: 'name' },
        { text: 'Apellido', value: 'last_name' },
        { text: 'Teléfono', value: 'contact' },
        { text: 'Rut', value: 'rut' },
        { text: 'Email', value: 'email' },
        { text: 'Creado por', value: 'created_by' },
        { text: 'Saldo pendiente a cobrar', value: 'customer_pending_debt', sortable: false },
        { text: 'Detalle', value: '_actions', sortable: false },
      ]
    },
  },

  watch: {
    tableOptions: {
      handler() {
        this.getCustomers()
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions(useCustomerListStore, ['getCustomers']),

    selectItem(item) {
      this.selectedCustomer = item
      this.showCustomerDetailModal = true
    },
  },

  async created() {
    await this.getCustomers()
  },
}
</script>

<style lang="scss" scoped>
.action-btn {
  color: #e2e2e2;
  min-width: 0 !important;
  padding: 0 !important;
}
</style>
