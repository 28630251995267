<template lang="pug">
v-container
  div.cartola.mt-3
    v-row.subtitle
      p.mr-5.mb-0 Saldo total del mes
    h1.ml-4.mt-2(style="color: #462DFA") {{ totalAmount | currency }}
  br
  v-row
    v-col(cols="3")
      div.d-flex.justify-start
        h4.mt-4.ml-3(style="color: #462DFA;") Selecciona por rango de fechas
    v-col(cols="4")
      div.d-flex.justify-center
        v-menu(ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="dates" transition="scale-transition" offset-y min-width="auto")
          template(v-slot:activator="{ on, attrs }")
            v-text-field(v-model="dateRangeText" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined label="Ingresa un rango de fechas"  clearable @click:clear="dates = []")
          v-date-picker(v-model="dates" range no-title scrollable color="primary" locale="es-CL")
            v-spacer
            v-btn(color="primary" @click="menu = false" outlined) Cerrar
            v-btn(color="primary" @click="handleSelectedMonth") Confirmar
    v-col(cols="5")
      div.d-flex.justify-end
        v-btn.mt-3(color="primary" style="font-weight: 400;" @click="exportSheet" :disabled="dates.length < 2")
          v-icon.mr-2 mdi-microsoft-excel
          span Exportar
  v-data-table(
    :headers="headers"
    class="elevation-0"
    :items="movements"
    hide-default-footer
    no-data-text="No hay movimientos"
    :loading="table.loading"
    loading-text="Cargando Movimientos..."
    )
    template(v-slot:item.transaction_date="{ item }")
      p {{ formatDate(item.transaction_date) }} hrs.
    template(v-slot:item.movement_type="{ item }")
      v-chip(label :color="getColor(item.movement_type, item.payment_gateway)" :text-color="getColorText(item.movement_type)" dark) {{ item.movement_type }} - {{ item.payment_gateway }}
    template(v-slot:item.estimates_wo="{ item }")
      p(v-if="item.estimates_wo !== null && item.estimates_wo !== '-'") N° {{ item.estimates_wo }}
      p(v-else) -
    template(v-slot:item.invoice_number="{ item }")
      p(v-if="item.invoice_number !== null && item.invoice_number !== '-'") N° {{ item.invoice_number }}
      p(v-else) -
    template(v-slot:item.supplier="{ item }")
      span.d-flex.align-center(v-if="item.supplier_name !== null && item.supplier_name !== '-'")
        p.mb-0.mr-1 {{ item.supplier_name }}
      span.d-flex.align-center(v-else-if="item.expense_supplier_name !== null && item.expense_supplier_name !== '-'")
        p.mb-0.mr-1 {{ item.expense_supplier_name }}
      p(v-else) Sin proveedor
    template(v-slot:item.payment_total="{ item }")
      p {{ item.payment_total | currency }}
    template(v-slot:item.description="{ item }")
      a.mr-2(v-if="item.sale_data.id" @click="$refs.saleDetail.open(item.sale_data.id)") {{ item.sale_data.id && `${item.description} N° ${item.sale_data.id}` }}
      p(v-else) {{ item.description }} <br/> {{ item.check_number && `Cheque ${item.check_qty} - N° ${item.check_number}` }}
    template(v-slot:item.actions="{ item }")
      v-icon.mr-2(v-if="item.receipt_file" color="primary" @click="openReceiptFile(item)") mdi-eye
  v-pagination.mt-4(v-model="page" :length="pageCount" circle color="primary" elevation=0)
  SaleDetail(ref="saleDetail")
  v-dialog(v-model="receiptFileModal" transition="dialog-top-transition" :width="modalWidth" content-class="elevation-0" style="border-radius: 16px;" persistent)
    v-card.pa-4(v-if="receiptFileModal" :loading="loading")
      template(slot="progress")
        v-progress-linear(indeterminate color="primary")
      .d-flex.justify-space-between
        h2.primary--text Tu Comprobante
        v-icon(color="primary" @click="closeModalReceipt") mdi-close
      .expense-date.mt-4.px-1 Fecha del retiro: {{ linkedExpenseDate?.length > 3 ? formatDate(linkedExpenseDate) : '-' }}
      v-img(:src="receiptFile" style="border: solid 1px #DDD; margin-top: 10px !important;" @click="changeModalWidth")
</template>
<script>
import { mapActions } from 'vuex'
import SaleDetail from '../sale/SaleDetail.vue'
import settings from '../../../settings'
export default {
  props: {
    tab: {
      type: Number,
      default: 5
    }
  },
  components: {
    SaleDetail,
  },
  data () {
    return {
      page: 1,
      rowCount: 0,
      offset: null,
      picker: [],
      pickerFormated: '',
      dates: [],
      menu: false,
      table: {
        loading: false
      },
      headers: [
        { text: 'Fecha y hora', value: 'transaction_date', width: '180px' },
        { text: 'Tipo de Movimiento', value: 'movement_type', align: 'left', width: '130px' },
        { text: 'OT asociada', value: 'estimates_wo', width: '120px' },
        { text: 'N° Factura', value: 'invoice_number', width: '120px' },
        { text: 'Proveedor', value: 'supplier', width: '120px' },
        { text: 'Monto', value: 'payment_total', width: '120px' },
        { text: 'Descripción', value: 'description', width: '200px' },
        { text: 'Acciones', value: 'actions' },
      ],
      movements: [],
      totalAmount: 0,
      receiptFileModal: false,
      receiptFile: null,
      linkedExpenseDate: null,
      modalWidth: 450,
      loading: false
    }
  },
  computed: {
    dateRangeText () {
      const startDate = this.dates[0]?.split('-').reverse().join('/') || 'Desde'
      const endDate = this.dates[1]?.split('-').reverse().join('/') || 'Hasta'
      if(this.dates.length === 1) return `${startDate} - Hasta`
      return `${startDate}  -  ${endDate}`
    },
    pageCount () {
      return Math.ceil(this.rowCount / 10)
    },
  },
  methods: {
    ...mapActions('inventory', ['listAllMovements', 'exportTransactionSheet']),
    async fetchData (dateStart = null, dateEnd = null) {
      this.table.loading = true
      this.movements = []
      await this.listAllMovements({ params: { page: this.page, fecha_inicio: dateStart, fecha_final: dateEnd } }).then(
        response => {
          this.movements = response.data.movements.results
          this.totalAmount = response.data.saldo
          this.rowCount = response.data.movements.count
          this.menu = false
        }
      )
      this.table.loading = false
    },
    getColor (type) {
      return type === 'Pago' || type === 'Ingreso de efectivo' || type === 'Depósito bancario' ? '#DBFFD6' : '#FFD6EC'
    },
    getColorText (type) {
      return type === 'Pago' || type === 'Ingreso de efectivo' || type === 'Depósito bancario' ? '#2DA71A' : '#A71A5E'
    },
    formatDatePicker (date) {
      if (!date) return null
      const [year, month, day] = date[0].split('-')
      if (date.length < 2) return [`${day}/${month}/${year}`, '']
      const [year2, month2, day2] = date[1].split('-')
      return [`${day}/${month}/${year}`, `${day2}/${month2}/${year2}`]
    },
    formatDate (date) {
      return new Intl.DateTimeFormat('cl-ES', { day: '2-digit', month: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric' }).format(new Date(date))
    },
    handleSelectedMonth () {
      this.$refs.menu.save(this.dates)
      this.selectedCorrectDate()
    },
    selectedCorrectDate () {
      if (this.dates[0] > this.dates[1]) {
        this.fetchData(this.dates[1], this.dates[0])
      } else {
        this.fetchData(this.dates[0], this.dates[1])
      }
    },
    getDate () {
      return new Intl.DateTimeFormat('cl-ES', { dateStyle: 'short' }).format(new Date())
    },
    generateFileAndDownload (data, headers, nameFile) {
      const blob = new Blob([data], {
        type: {
          type: headers['content-type']
        }
      })
      const blobUrl = window.URL.createObjectURL(blob)
      const tempLink = document.createElement('a')
      tempLink.style.display = 'none'
      tempLink.href = blobUrl
      tempLink.setAttribute('download', nameFile)
      document.body.appendChild(tempLink)
      tempLink.click()
      document.body.removeChild(tempLink)
    },
    exportSheet () {
      const fechaInicio = this.dates[0] < this.dates[1] ? this.dates[0] : this.dates[1]
      const fechaFinal = this.dates[0] > this.dates[1] ? this.dates[0] : this.dates[1]
      this.exportTransactionSheet({ data: { fecha_inicio: fechaInicio, fecha_final: fechaFinal } }).then(
        response => {
          this.generateFileAndDownload(response.data, response.headers, `Cartola - ${this.getDate()}.xlsx`)
        }
      )
    },
    openReceiptFile(item) {
      this.loading = true
      this.receiptFile = `${settings.API_URL}${item.receipt_file}`
      this.receiptFileModal = true
      this.linkedExpenseDate = item.linked_expense_date
      this.loading = false
    },
    closeModalReceipt () {
      this.modalWidth = '450'
      this.receipt_file = null
      this.receiptFileModal = false
    },
    changeModalWidth () {
      this.modalWidth = '1000'
    }
  },
  watch: {
    page () {
      if (this.dates.length === 2) {
        this.selectedCorrectDate()
      } else {
        this.fetchData()
      }
    },
    picker (newVal) {
      if (this.picker.length === 2) {
        this.pickerFormated = this.formatDatePicker(this.picker).join('~')
      }
      if (newVal.length === 0) {
        this.fetchData()
      }
    },
    tab (newVal) {
      if (newVal === 5) {
        // this.fetchData()
        this.dates = []
      }
    },
    dates: {
      handler (value) {
        if(this.dates.length === 2) {
          const date1 = new Date(this.dates[0]).getTime();
          const date2 = new Date(this.dates[1]).getTime();
          if(date1 > date2) this.dates = this.dates.reverse()
        }
        if (this.dates.length === 0) {
          this.fetchData()
        }
      },
      deep: true
    },
  },
  created () {
    this.fetchData()
  }
}
</script>
<style lang="scss" scoped>
.cartola {
  height: 100px;
  background-image: url('./../../assets/img/cartola.png');
  background-size: cover;
  background-position: center;
  border-radius: 16px;
}
.subtitle {
  padding: 20px 0 0 30px;
  color: #462DFA;
}
.expense-date {
  color: #6c6c6c;
  font-weight: 500;
  font-size: 1rem;

}
</style>
