<template lang="pug">
v-menu(v-if="servicesTech.length && services" v-model="isOpen" offset-x :close-on-content-click="false")
    template(v-slot:activator="{ on, attrs }")
      .d-inline-block
        v-badge(v-if="showAdd" key="add-btn" bordered bottom color="#3EABFF" content="T" offset-x="12" offset-y="12")
          v-btn(style="border-style: dotted;" size=32 v-bind="attrs" v-on="on" icon)
            v-icon mdi-account-plus-outline
        v-badge(v-if="item.date_assigned" v-for="(item, index) in servicesTech" bordered bottom color="#3EABFF" content="T" offset-x="12" offset-y="12" :key="index")
          v-avatar.mr-1(color="#FFD699" size=32 dark  v-bind="attrs" v-on="on") {{getAvatarText(item.assigned_to.id)}}
    v-card(v-if="servicesTech" width="400px")
      v-card-text(v-for="(item, index) in servicesTech" :key="index")
        div Asignar para trabajo
        v-form(ref="form" @submit.prevent="onSubmit(index)")
          v-autocomplete(
            v-model="item.assigned_to"
            :label="`Técnico para ${item.service_name}`"
            placeholder="Buscar"
            :loading="saving"
            :items="technicians"
            :rules="[v => !!v || 'Este campo es requerido']"
            item-text="first_name"
            :disabled="available"
            item-value="id")
          v-btn(v-if="!available" block color="primary" type="submit" :loading="saving") Guardar
        //- .mt-4
          //- p Los siguientes repuestos deben ser añadidos como piezas definitivas
          h3.primary--text Repuestos
          v-row.mt-0(v-for="(saleItem, index) in item.sale_items" :key="index")
            v-col(cols="8")
              v-text-field(placeholder="Repuesto 1" :value="saleItem.piece ? saleItem.piece_name : saleItem.new_piece_name" :disabled="true" outlined hide-details dense)
            v-col(cols="4")
              v-text-field(placeholder="1" v-model="saleItem.quantity" :disabled="true" outlined hide-details dense)
          v-row(dense v-if="hasNewPieces(item)")
            v-col(cols="12")
              .new-pieces-alert
                .d-flex.justify-space-between
                  .d-flex.align-start
                    v-icon.mr-2 mdi-information-outline
                    span Los siguientes repuestos deben ser creados en el inventario:
                      ul
                        li(v-for="(saleItem, index) in item.newPieces" :key="index") {{ saleItem.new_piece_name }}
                  v-btn.btn-add-piece(text @click="handleAddPieces(item.newPieces)" :loading="saving") Añadir

      //- CreateNewPiecesModal(
      //-   v-model="showCreateNewPiecesModal"
      //-   :itemsToCreate="saleItemsToCreate"
      //-   @close="showCreateNewPiecesModal = false"
      //-   @created="handlePiecesCreated"
      //- )

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CreateNewPiecesModal from '@/components/sale/subcomponents/CreateNewPiecesModal.vue'

export default {
  components: {
    CreateNewPiecesModal,
  },
  props: {
    defaultTechId: {
      type: Number,
      default: null
    },
    services: {
      type: Array
    },
    ot: {
      Object
    },
    available: {
      type: Boolean,
    }
  },
  data () {
    return {
      isOpen: false,
      myTdTech: this.techId,
      servicesTech: [],
      saleItemsToCreate: [],
      // showCreateNewPiecesModal: false,
      // allSaleItemsToSave: {},
      saving: false,
    }
  },
  methods: {
    ...mapActions('ot', ['setOTServiceTech']),
    async onSubmit (formIndex) {
      this.saving = true
      if (!this.$refs.form[formIndex].validate()) {
        this.saving = false
        return
      }
      let response
      // TODO: hacer requests en paralelo
      for (const item of this.servicesTech) {
        const techId = item.assigned_to?.id || item.assigned_to
        if (!techId) continue
        // const techId = Object.prototype.hasOwnProperty.call(item.assigned_to, 'id') ? item.assigned_to.id : item.assigned_to
        // const saleItems = this.allSaleItemsToSave[item.id]
        response = await this.setOTServiceTech({ OTServiceId: item.id, data: { id: techId } })
      }

      this.saving = false

      if (response.status === 200) {
        this.$emit('save', { type: 'success', message: 'Técnicos agregados con éxito', show: true, color: 'green' })
        this.isOpen = false
      }
    },
    getTechById (id) {
      return this.technicians.find(el => el.id === id)
    },
    getAvatarText (id) {
      const tech = this.getTechById(id)
      if (tech) {
        return tech.first_name.charAt(0) + tech.last_name.charAt(0)
      }
      return ''
    },
    getTechnician (id) {
      return this.technicians.filter(tech => tech.id === id)[0]
    },
    getDefaultTechId (estimateId) {
      const estimate = this.ot.estimate.filter(e => e.id === estimateId)[0]
      return estimate?.evaluation !== null ? estimate?.evaluation.assigned_to : 1
    },
    // handlePiecesCreated (piecesCreated) {
    //   this.saleItemsToCreate.forEach((newItem, newItemIndex) => {
    //     const newPiece = piecesCreated[newItemIndex]
    //     const service = this.services[0].services.filter(s => s.id === newItem.estimate_services)[0]
    //     service.sale_items.some((saleItem, index) => {
    //       if (saleItem.id === newItem.id) {
    //         service.sale_items[index] = {
    //           ...saleItem,
    //           new_piece_name: null,
    //           piece: newPiece.id,
    //         }
    //         this.allSaleItemsToSave[service.id] = [
    //           ...this.allSaleItemsToSave[service.id] || [],
    //           {
    //             id: saleItem.id,
    //             piece: newPiece.id,
    //           }
    //         ]
    //         return true
    //       }
    //     })
    //   })
    // },
    // hasNewPieces (service) {
    //   return service.sale_items.some(el => !el.piece)
    // },
    // handleAddPieces (saleItems) {
    //   this.saleItemsToCreate = saleItems
    //   this.showCreateNewPiecesModal = true
    // }
  },
  computed: {
    ...mapGetters('user', ['technicians']),
    showAdd () {
      for (const item of this.servicesTech) {
        if (!item.date_assigned) {
          return true
        }
      }
      return false
    },
  },
  watch: {
    services () {
      const services = this.services.filter(service => service.otId === this.ot.id)
      this.servicesTech = services.length === 0 ? [] : services[0].services.map(el => {
        el = { ...el }
        el.assigned_to = el.assigned_to ? this.getTechnician(el.assigned_to) : this.getTechnician(this.getDefaultTechId(el.estimate))
        el.newPieces = el.sale_items.filter(el => el.new_piece_name != null)
        return el
      })
    },
    OTId() {
      this.fetchData()
    }
  }
}
</script>
<style lang="scss">
.new-pieces-alert {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  border: 1px solid #8E8E8E;
  border-radius: 4px;
  padding: 8px 12px;
}
</style>
