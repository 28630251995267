import axios from '../../plugins/axios'

export const listPieces = ({ commit }, { params } = {}) => {
  return axios.get('/inventory/piece/', { params })
    .then(res => {
      commit('SET_PIECES', res.data)
      return res
    })
    .catch(err => err.response)
}

export const deletePieces = (_, { id } = {}) => {
  return axios.delete(`inventory/work_order_pieces/${id}/`)
    .catch(err => err.response)
}

export const searchPiece = ({ commit }, { param } = {}) => {
  return axios.get(`/inventory/piece/?query=${param}`)
    .then(res => {
      commit('SET_PIECES', res.data)
      return res
    })
    .catch(err => err.response)
}
export const listPettyCash = (_, { param } = {}) => {
  return axios.get(`/inventory/daily_register_balance/?page=${param.page}`)
    .then(res => {
      return res
    })
    .catch(err => err.response)
}

export const createExpense = (_, { data } = {}) => {
  return axios.post('inventory/expense/', data)
    .catch(err => err.response)
}

export const updateExpense = (_, { data } = {}) => {
  return axios.put(`/inventory/expense/${data.id}/`, data)
    .catch(err => err.response)
}

export const closePettyCash = (_, { data } = {}) => {
  return axios.post('inventory/close_cash_register', data)
    .catch(err => err.response)
}

export const closePettyCashInfo = (_, { param } = {}) => {
  return axios.get('/inventory/cash_register_closing', { param })
    .then(res => {
      return res
    })
    .catch(err => err.response)
}

export const listDailyMovements = (_, { params } = {}) => {
  return axios.get(`/inventory/daily_movements/?page=${params.page}`, { params })
    .then(res => {
      return res
    })
    .catch(err => err.response)
}
export const listCashWithdrawals = (_, { params } = {}) => {
  return axios.get(`/inventory/work_order_deposit/get_cash_withdrawals/?page=${params.page}`)
    .then(res => {
      return res
    })
    .catch(err => err.response)
}

export const listAllMovements = (_, { params } = {}) => {
  return axios.get('/inventory/work_order_deposit/transaction_sheet/', { params })
    .then(res => {
      // console.log(res, 'listAllmovements')
      return res
    })
    .catch(err => err.response)
}

export const listPendingPayments = (_, { params }) => {
  return axios.get(`/inventory/expense/get_pending_expenses/?page=${params.page}`)
    .then(res => {
      // console.log(res, 'pendingpayments')
      return res
    })
    .catch(err => err.response)
}

export const payPendingPayment = (_, { data } = {}) => {
  return axios.put(`/inventory/expense/${data.id}/pay_expense/`, data)
    .catch(err => err.response)
}

export const payExpenseWithReceipt = (_, { data, id } = {}) => {
  return axios.put(`/inventory/expense/${id}/pay_expense_with_receipt/`, data)
    .catch(err => err.response)
}

export const listSuppliers = (_, { params } = {}) => {
  return axios.get('/inventory/supplier/', { params })
    .then(res => {
      return res
    })
    .catch(err => err.response)
}

export const createSupplier = (_, { data } = {}) => {
  return axios.post('/inventory/supplier/', data)
    .catch(err => err.response)
}

export const getSupplierPieces = ({ commit }, { id, params } = {}) => {
  return axios.get(`/inventory/supplier/${id}/get_supplier_pieces/`, { params })
    .then(res => {
      commit('SET_PIECES', res.data)
      return res
    })
    .catch(err => err.response)
}

export const createSupplierStockMovement = (_, { data } = {}) => {
  return axios.post('/inventory/supplier_stock_movement', data)
    .catch(err => err.response)
}

export const exportEstimatesSheet = (_, { data } = {}) => {
  return axios.post('inventory/estimate/export_estimates_sheet/', data, { responseType: 'arraybuffer' })
    .catch(err => err.response)
}

export const exportWorkOrderSheet = (_, { data } = {}) => {
  return axios.post('inventory/work_order/export_work_order_sheet/', data, { responseType: 'arraybuffer' })
    .catch(err => err.response)
}

export const exportPiecesSheet = (_, { data } = {}) => {
  return axios.post('inventory/work_order/export_pieces_sheet/', data, { responseType: 'arraybuffer' })
    .catch(err => err.response)
}

export const exportTransactionSheet = (_, { data } = {}) => {
  return axios.post('inventory/work_order_deposit/export_transaction_sheet/', data, { responseType: 'arraybuffer' })
    .catch(err => err.response)
}

export const getVehicleMilage = (_, { id, params } = {}) => {
  return axios.get(`inventory/work_order/${id}/vehicle_milage/`, { params })
    .then(res => {
      return res
    })
    .catch(err => err.response)
}

export const createVehicleMilage = (_, { id, data } = {}) => {
  return axios.post(`inventory/work_order/${id}/vehicle_milage/`, data)
    .then(res => {
      return res
    })
    .catch(err => err.response)
}

export const getStockMovementList = (_, { params } = {}) => {
  return axios.get('/inventory/expense/get_stock_movement_payments/', { params })
    .then(res => {
      return res
    })
    .catch(err => err.response)
}

export const listExpenseGroups = (_, { params } = {}) => {
  return axios.get('/inventory/expense_group/', { params })
    .then(res => {
      return res
    })
    .catch(err => err.response)
}

export const downloadPdf = (_, { id } = {}) => {
  return axios.get(`/inventory/download_pdf/${id}`, { responseType: 'blob' })
    .then(res => {
      return res
    })
    .catch(err => err.response)
}

export const listAgreements = (_, { params } = {}) => {
  return axios.get('/inventory/agreement/', { params })
    .then(res => {
      return res
    })
    .catch(err => err.response)
}
export const listClientsWithAccountReceivable = (_, { params } = {}) => {
  return axios.get('/inventory/get_clients_with_accounts_receivable', { params })
    .then(res => {
      return res
    })
    .catch(err => err.response)
}

export const listAccountReceivable = (_, { params } = {}) => {
  return axios.get(`/inventory/account-receivable/?page=${params.page}`, { params })
    .then(res => {
      return res
    })
    .catch(err => err.response)
}

export const createAccountReceivableAgreement = (_, { dataAccountReceivable }) => {
  // crear movimiento por cobrar en presupuesto con convenio
  return axios.post('inventory/account-receivable/', dataAccountReceivable)
    .then(res => {
      return res
    })
    .catch(err => err.response)
}

export const receivePaymentAgreement = (_, { id, data } = {}) => {
  return axios.put(`/inventory/account-receivable/${id}/collect_credit/`, data)
    .catch(err => err.response)
}

export const downloadOTPdf = (_, { id, type } = {}) => {
  return axios.get(`/inventory/download_ot_pdf/${id}/${type}`, { responseType: 'blob' })
    .then(res => {
      return res
    })
    .catch(err => err.response)
}

export const getRecommendations = (_, { id, params } = {}) => {
  return axios.get(`inventory/work_order/${id}/get_recommendations/`, { params })
    .then(res => {
      return res
    })
    .catch(err => err.response)
}

export const createRecommendation = (_, { data } = {}) => {
  return axios.post('inventory/recommendation/', data)
    .catch(err => err.response)
}
export const updateRecommendation = (_, { id, data } = {}) => {
  return axios.put(`/inventory/recommendation/${id}/`, data)
    .catch(err => err.response)
}

export const getCurrentBalance = () => {
  return axios.get('inventory/cash_register_closing/current_balance/')
    .then(res => ({ data: res.data, response: res }))
    .catch(error => ({ error }))
}

export const getLastClosing = () => {
  return axios.get('inventory/cash_register_closing/last_closing')
    .then(res => ({ data: res.data, response: res }))
    .catch(error => ({ error }))
}

export const getSupplierExpenses = () => {
  return axios.get('inventory/supplier_expenses/')
    .then(res => res)
    .catch(error => ({ error }))
}

export const deleteSupplierExpense = (_, { id }) => {
  return axios.delete(`inventory/supplier_expenses/${id}/`)
    .then(res => res)
    .catch(error => ({ error }))
}

export const createSupplierExpense = (_, { data }) => {
  console.log(data)
  return axios.post('inventory/supplier_expenses/', data)
    .then(res => res)
    .catch(error => ({ error }))
}

export const updateSupplierExpense = (_, { data }) => {
  return axios.put(`inventory/supplier_expenses/${data.id}/`, data)
    .then(res => res)
    .catch(error => ({ error }))
}

export const getSupplierPrepaidExpenses = (_, { id }) => {
  return axios.get(`inventory/supplier_expenses/${id}/prepaid_expenses/`)
    .then(res => res)
    .catch(error => ({ error }))
}

export const listPrepaidExpenses = () => {
  return axios.get(`/inventory/expenses/get_all_prepaid_expenses/`)
    .then(res => res)
    .catch(err => err.response)
}

export const createNewPieces = (_, data) => {
  return axios.post('inventory/create_new_pieces/', data)
    .catch(err => err.response)
}

export const listCategoryService = () => {
  return axios.get(`/inventory/service_category/`)
    .then(res => res)
    .catch(err => err.response)
}

export const createNewService = (_, data) => {
  return axios.post(`/inventory/create_new_service/`, data)
    .then(res => res)
    .catch(err => err.response)
}

export const exportJobsRepaired = (_, { params } = {}) => {
  return axios.post(`/inventory/job_list/export_job_list_sheet/`, { params }, { responseType: 'arraybuffer' })
    .then(res => res)
    .catch(err => err.response)
}

export const getListSupplierPieces = ({ commit }, { params } = {}) => {
  return axios.get(`/inventory/list_supplier_pieces`, { params })
    .then(res => {
      return res
    })
    .catch(err => err.response)
}

export const getMerchandiseEntriesRequests = (_, { params } = {}) => {
  return axios.get(`/inventory/merchandise_entry_request/`, { params })
    .then(res => res)
    .catch(err => err.response)
}

export const getMerchandiseEntryRequest = (_, { id }) => {
  return axios.get(`/inventory/merchandise_entry_request/${id}/details/`)
    .then(res => res)
    .catch(err => err.response)
}

export const createMerchandiseEntryRequest = (_, data) => {
  return axios.post(`/inventory/merchandise_entry_request/`, data)
    .then(res => res)
    .catch(err => err.response)
}

export const updateMerchandiseEntryRequest = (_, { id, data }) => {
  return axios.put(`/inventory/merchandise_entry_request/${id}/edit_merchandise_request/`, data)
    .then(res => res)
    .catch(err => err.response)
}

export const updateAproveMerchandiseEntryRequest = (_, { id, data }) => {
  return axios.put(`/inventory/merchandise_entry_request/${id}/edit_aprove_merchandise_request/`, data)
    .then(res => res)
    .catch(err => err.response)
}

export const deleteMerchandiseEntryRequest = (_, { id }) => {
  return axios.delete(`/inventory/merchandise_entry_request/${id}/`)
    .then(res => res)
    .catch(err => err.response)
}

export const setSolicitudeStore = ({ commit }, data) => {
  commit('SET_SOLICITUDE', data)
}

export const manualRejection = (_, data) => {
  return axios.post(`/inventory/manual_rejection`, data)
    .then(res => res)
    .catch(err => err.response)
}

export const toggleCommissionFreeJob = (_, data) => {
  return axios.post(`/inventory/toggle_commission_free_job`, data)
    .then(res => res)
    .catch(err => err.response)
}