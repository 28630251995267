<template lang="pug">
v-container.printable()
  div.stonks.mt-3.hide-print
    v-row.subtitle
      p.mr-5.mb-0 Saldo total del día
      p.mb-0 {{formatDateDay(dateSelected)}}
    h1.ml-4.mt-2(style="color: #fff") {{ totalAmount | currency }}
  br
  v-row
    v-col(cols="3")
      div.d-flex.justify-start
        h4.mt-3(style="color: #462DFA;") Últimos movimientos del día de hoy
    v-col(cols="3")
      div.d-flex.justify-center
        v-menu(ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto")
          template(v-slot:activator="{ on, attrs }")
            v-text-field(v-model="dateFormated" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined)
          v-date-picker(v-model="date" no-title scrollable color="primary" first-day-of-week="1" locale="es-CL")
            v-spacer
            v-btn(color="primary" @click="menu = false" outlined) Cerrar
            v-btn(color="primary" @click="handleDateSelected") Confirmar
    v-col(cols="6").hide-print
      div
        .d-flex.justify-end.mb-2
          v-btn.mr-2(color="primary" dark style="font-weight: 400;" @click="print()")
            v-icon.mr-2 mdi-printer
            span Imprimir
          v-btn.mr-2(color="primary" dark style="font-weight: 400;" target="_blank" :disabled="!lastCloseBalance" outlined :href="lastCloseBalance?.closing_report_pdf")
            v-icon.mr-2 mdi-file-chart
            span Descargar Cierre Diario
          v-btn.ml-3(@click="dialog = true" :disabled="confirmClose" color="primary" outlined style="font-weight: 400;")
            v-icon.mr-1 mdi-lock
            span Cierre diario
        .d-flex.justify-end
          v-btn.ml-3(color="primary" dark @click="openModalExpenses(false)" style="font-weight: 400;")
            v-icon.mr-2() mdi-cash-remove
            span Retiro de efectivo
          v-btn.ml-3(color="primary" dark @click="openModalExpenses(true)" style="font-weight: 400;")
            v-icon.mr-2 mdi-cash-register
            span Nuevo Gasto
  v-data-table(
    :headers="headers"
    class="elevation-0"
    :items="movements"
    hide-default-footer
    no-data-text="No hay Movimientos"
    :loading="table.loading "
    loading-text="Cargando Movimientos..."
    )
    template(v-slot:item.hour="{ item }")
      p {{formatDateHour(item.transaction_date)}} hrs.
    template(v-slot:item.movement_type="{ item }")
      v-chip(label :color="getColor(item.movement_type)" :text-color="getColorText(item.movement_type)" dark) {{ item.movement_type }} - {{ item.payment_gateway }}
    template(v-slot:item.OT="{ item }")
      p(v-if="item.work_order !== null") N° {{ item.work_order }}
      p(v-else) -
    template(v-slot:item.payment_total="{ item }")
      p {{ item.payment_total | currency}}
    template(v-slot:item.description="{ item }")
      a.mr-2(v-if="item.sale_data.id" @click="$refs.saleDetail.open(item.sale_data.id)") {{ item.sale_data.id && `${item.description} N° ${item.sale_data.id}` }}
      p(v-else) {{ item.description }} <br/> {{ item.check_number && `Cheque ${item.check_qty} - N° ${item.check_number}` }}
    template(v-slot:item.supplier="{ item }")
      p(v-if="item.supplier_name !== null && item.supplier_name !== '-'") {{ item.supplier_name }}
      p(v-else) Sin proveedor
    template(v-slot:item.detail="{ item }")
      v-icon.mr-2(v-if="item.receipt_file" color="primary" @click="openReceiptFile(item)") mdi-eye
  v-pagination.mt-4(v-model="page" :length="pageCount" circle color="primary" elevation=0)
  //- Modal de confirmación de cierre de caja
  v-dialog(v-model="dialog" transition="dialog-top-transition" width="450" content-class="elevation-0" style="border-radius: 16px;")
    v-card(style="padding: 50px 20px 20px 20px;")
      div.money-img
      v-card-title(style="color: #4C7AEC; text-align: center; font-size: 20px;") ¿Seguro quieres cerrar caja chica con {{ currentBalance?.closing_balance | currency }}?
      v-card-text Al confirmar esta opción no puedes modificar el monto.
      v-card-text
        div Detalle de cierre:
        div Saldo Transbank: {{ currentBalance?.transbank_balance | currency }}
        div Saldo efectivo: {{ currentBalance?.cash_balance | currency }}
        div Saldo transferencia: {{ currentBalance?.transfer_balance | currency }}
        div Saldo crédito: {{ currentBalance?.credit_balance | currency }}
        div Saldo débito: {{ currentBalance?.debit_balance | currency }}
        div Saldo cheque: {{ currentBalance?.check_balance | currency }}

      v-card-actions
        v-row
          v-col(cols="6")
            v-btn(@click="dialog = false" block elevation="0" color="#4C7AEC" outlined) Cancelar
          v-col(cols="6")
            v-btn.mr-5(color="#4C7AEC" dark @click="confirmDailyClose()" block) Confirmar
  alert(ref="alert")
  ModalExpenses(ref="ModalExpenses" :isExpense="isExpense" @refreshData="successAction")
  SaleDetail(ref="saleDetail")
  v-dialog(v-model="receiptFileModal" transition="dialog-top-transition" :width="modalWidth" content-class="elevation-0" style="border-radius: 16px;" persistent)
    v-card.pa-4(v-if="receiptFileModal" :loading="loading")
      template(slot="progress")
        v-progress-linear(indeterminate color="primary")
      .d-flex.justify-space-between
        h2.primary--text Tu Comprobante
        v-icon(color="primary" @click="closeModalReceipt") mdi-close
      .expense-date.mt-4.px-1 Fecha del retiro: {{ linkedExpenseDate?.length > 3 ? formatDate2(linkedExpenseDate) : '-' }}
      v-img(:src="receiptFile" style="border: solid 1px #DDD; margin-top: 10px !important;" @click="changeModalWidth")

</template>
<script>
import { mapActions } from 'vuex'
import Alert from '../shared/alert.vue'
import { calculateDepositAmount } from '@/utils/deposit'
import ModalExpenses from './ModalExpenses.vue'
import SaleDetail from '../sale/SaleDetail.vue'
import settings from '../../../settings'

export default {
  props: {
    tab: {
      type: Number,
      default: 1
    }
  },
  components: {
    Alert,
    ModalExpenses,
    SaleDetail
  },
  data () {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateFormated: this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
      menu: false,
      table: {
        loading: false
      },
      page: 1,
      pageCount: 0,
      headers: [
        { text: 'Hora', value: 'hour', width: '100px' },
        { text: 'Tipo de Operación', value: 'movement_type', align: 'left', width: '130px' },
        { text: 'OT asociada', value: 'OT', width: '120px', align: 'left' },
        { text: 'Monto', value: 'payment_total', width: '100px' },
        { text: 'Descripción', value: 'description', width: '250px' },
        { text: 'Proveedor', value: 'supplier', width: '250px' },
        { text: 'Detalle', value: 'detail' }
      ],
      dialog: false,
      movements: [],
      today: new Date(),
      currentBalance: null,
      dateSelected: new Date(),
      confirmClose: false,
      closingBalance: null,
      lastCloseBalance: null,
      isExpense: false,
      receiptFileModal: false,
      receiptFile: null,
      linkedExpenseDate: null,
      modalWidth: 450,
      loading: false
    }
  },
  methods: {
    ...mapActions('inventory', ['listDailyMovements', 'closePettyCashInfo', 'closePettyCash', 'getCurrentBalance', 'getLastClosing']),
    async fetchData (selectedDate = null) {
      this.table.loading = true
      this.listDailyMovements({ params: { fecha: selectedDate, page: this.page } }).then(
        response => {
          if (response.status === 200) {
            this.movements = response.data.results.filter(deposit => deposit.state !== 'Pendiente')
            this.dateSelected = response.data.results.length > 0 ? response.data.results[0].created_at : new Date()
            this.pageCount = Math.ceil(response.data.count / 10)
          } else {
            this.movements = []
            this.pageCount = 0
          }
        }
      )
      this.closePettyCashInfo().then(
        response => {
          if (response.data.results.length > 0) {
            const data = response.data.results
            const lastClosing = data.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())[0]
            this.lastClose = new Date(lastClosing.date)
            this.closingBalance = lastClosing.closing_balance
            this.confirmClose = this.today.getDate() === this.lastClose.getDate() && this.today.getMonth() === this.lastClose.getMonth() && this.today.getFullYear() === this.lastClose.getFullYear()
          }
        }
      )
      const { data: currentBalance } = await this.getCurrentBalance()
      this.currentBalance = currentBalance

      const { data: lastClosing } = await this.getLastClosing()
      this.lastCloseBalance = lastClosing
      this.table.loading = false
    },
    successAction (data) {
      this.fetchData()
      this.$refs.alert.handleShowAlert(data.type, data.message, data.show, data.color)
    },
    getColor (type) {
      return type === 'Pago' || type === 'Ingreso de efectivo' || type === 'Depósito bancario' ? '#DBFFD6' : '#FFD6EC'
    },
    getColorText (type) {
      return type === 'Pago' || type === 'Ingreso de efectivo' || type === 'Depósito bancario' ? '#2DA71A' : '#A71A5E'
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    formatDateDay (date) {
      return new Intl.DateTimeFormat('cl-ES', { dateStyle: 'long' }).format(new Date(date))
    },
    formatDateHour (date) {
      return new Intl.DateTimeFormat('cl-ES', { timeStyle: 'short' }).format(new Date(date))
    },
    formatDate2 (date) {
      return new Intl.DateTimeFormat('cl-ES', { day: '2-digit', month: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric' }).format(new Date(date))
    },
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    handleDateSelected () {
      this.$refs.menu.save(this.date)
      this.fetchData(this.date)
    },
    openModalExpenses (item) {
      this.isExpense = item
      this.$refs.ModalExpenses.open(item)
    },
    openReceiptFile(item) {
      this.loading = true
      this.receiptFile = `${settings.API_URL}${item.receipt_file}`
      this.receiptFileModal = true
      this.linkedExpenseDate = item.linked_expense_date
      this.loading = false
    },
    closeModalReceipt () {
      this.modalWidth = '450'
      this.receipt_file = null
      this.receiptFileModal = false
    },
    print () {
      window.print()
    },
    async confirmDailyClose () {
      await this.closePettyCash().then(
        () => {
          this.dialog = false
          this.fetchData()
          this.$refs.alert.handleShowAlert('success', 'Caja cerrada con éxito', true, 'green')
        }
      )
    },
    changeModalWidth () {
      this.modalWidth = '1000'
    }
  },
  watch: {
    date () {
      this.dateFormated = this.formatDate(this.date)
    },
    tab (newVal) {
      if (newVal === 1) {
        this.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        this.fetchData()
      }
    },
    page () {
      this.fetchData(this.date)
    }
  },
  computed: {
    totalAmount () {
      return calculateDepositAmount(this.movements)
    },
  },
  created () {
    this.fetchData()
  }
}
</script>
<style lang="scss" scoped>
.stonks {
  height: 100px;
  background-image: url('./../../assets/img/stonks.png');
  background-size: cover;
  background-position: center;
  border-radius: 16px;
}
.subtitle {
  padding: 20px 0 0px 30px;
  color: #fff;
}
.expense-date {
  color: #6c6c6c;
  font-weight: 500;
  font-size: 1rem;

}
</style>
<style>
@media print {
  body * {
    background: #fff !important;
  }
  body .printable {
    display: block;
  }
  .hide-print {
    display: none !important;
  }
  .v-data-table, .v-data-table__wrapper {
    overflow: hidden !important;
  }
}
</style>
