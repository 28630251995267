<template lang="pug">
div
  v-card.pt-1.mb-5.pb-5.repairCard(v-for="d in reparations" :key="d.id")
    v-row
      v-col(cols="9")
        //- v-card-text.py-1(style="font-size: 12px;") Asignado: {{ formatDate(d.estimate_updated_at) }}
        v-card-text.py-1(style="font-size: 12px;") Asignado: {{ formatDate(d.date_assigned) }}
        v-card-title.pb-5.pt-0(style="color: #462DFA") {{d.service_name}}
        v-card-subtitle.py-1(style="color: #7B90DA; font-size: 16px;") {{d.vehicle_brand}} - {{d.vehicle_model}} - {{d.vehicle_patent}}
        v-card-text.pb-0(style="color: #7B90DA; font-size: 16px;")
          | Asociado a OT N°: {{ d.work_order }}
      v-col.d-flex.align-center(cols="3")
        v-btn.chevronButton(depressed width="40px" height="40px" color="#462DFA" @click="$router.push({ name: 'Detalle Reparaciones', params: { otID: d.work_order, estimateID: d.estimate }})")
          v-icon(style="color: #fff;" dense) mdi-chevron-right
  v-pagination.mt-4.mb-4(v-model="page" :length="pageCount" circle color="primary" elevation=0)
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      page: 1,
      rowCount: 0,
      reparations: []
    }
  },
  methods: {
    ...mapActions('ot', ['listJobs', 'listJobsOrder']),
    fetchData () {
      this.listJobsOrder({ params: { page: this.page, is_job_to_repair: true } }).then(
        response => {
          this.rowCount = response.data.count
          this.reparations = response.data.results
        }
      )
    },
    formatDate (date) {
      return new Intl.DateTimeFormat('cl-ES', { dateStyle: 'short', timeStyle: 'short', hour12: true }).format(new Date(date))
    }
  },
  computed: {
    pageCount () {
      return Math.ceil(this.rowCount / 10)
    }
  },
  created () {
    this.fetchData()
  },
  watch: {
    page () {
      this.fetchData()
    }
  }
}
</script>
<style lang="scss" scoped>
.v-card__title {
  font-weight: 600 !important;
}
  a {
    color: #A3A2A1 !important;
  }
.repairCard {
  border-left: 16px solid #462DFA;
}
.chevronButton {
  min-width: 40px !important;
  margin-left: 5px;
  margin-top: 12px;
}
</style>
