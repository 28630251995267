<template lang="pug">
v-container(v-if="!loading")
  .d-flex.align-center.w-100.mt-n8.pa-3
    v-btn(elevation="0" color="primary" @click="$router.push({ name: 'OT' })" icon)
      v-icon mdi-arrow-left
    v-btn.ml-2(
      v-for="action in actions" :key="action.text"
      :color="action.bgColor" :style="`color: ${action.color}; border: ${action.borderStyle}`"
      @click="action.action"
      rounded large
    )
      v-icon.mr-2 {{ action.icon }}
      span {{ action.text }}
      v-icon(dense) mdi-chevron-right

    v-menu(offset-x left)
      template(v-slot:activator="{ on, attrs }")
        v-btn.ml-auto(style="background: #E4EAFF ;color: #407BFF;" icon v-bind="attrs" v-on="on")
          v-icon mdi-file-download-outline
      v-list
        v-list-item(@click="download('recepcion')" v-if="selectedOt.pdf_recepcion")
          v-list-item-title PDF Recepción
        v-list-item(@click="download('entrega')" v-if="selectedOt.pdf_entrega")
          v-list-item-title PDF Entrega

  .otDataContainer(style="margin-top: 10px;")
    div.pl-3.pr-3.my-6
      v-tabs(color="primary" next-icon="mdi-arrow-right-bold-box-outline" prev-icon="mdi-arrow-left-bold-box-outline" show-arrows v-model="tab" background-color="#F9FAFE" )
        v-tab.d-flex.flex-column.pt-4(v-for="tab in tabs" :key="tab.id" @click="goToDetailOt(tab.id)" style="color: #C8C8C8;" :selected="tab.selected" active-class="selectedOTtab")
          h3 OT N° {{ tab.id }}
          p {{ formatDate(tab.created_at) }}
    HeadersCards(@showAlert="handlerShowAlert" :vehicle_milage="vehicle_milage" :haveMilage="haveMilage" :services="services")
    br
    Budgets(:selectedOt="selectedOt" @save="fetchData")
    History
    Services(@refreshData="fetchData")
    PartList
    ItemList(v-if="!loading && selectedOt !== null" :selectedOt="selectedOt" @refresh="fetchData")
    Expenses
    Recommendations
    //- Comentario de entrega OT
    v-container
      v-card
        .d-flex
          v-expansion-panels(accordion)
            v-expansion-panel
              v-expansion-panel-header.pl-5
                v-card-title.budgetTitle.pl-1.py-0.pt-1 Comentario de Entrega
              v-expansion-panel-content
                div.pa-4 {{ selectedOt.delivery_comment ? selectedOt.delivery_comment : 'No existen comentarios' }}

  Totals
  alert(ref="alert")
  OTDiagnosticReceptionistForm(ref="diagnosticReceptionistForm" @save="fetchData")
  ApproveBudget(v-if="selectedOt !== null" ref="ApproveBudget" :otID="selectedOt.id" :budgets="budgets" @showAlert="successAction")
  RejectBudget(v-if="selectedOt !== null" ref="RejectBudget" @showAlert="successAction")
  ApproveWork(v-if="selectedOt !== null" ref="ApproveWork" :otID="selectedOt.id" @reloadData="successAction")
  ClosePayment(v-if="selectedOt !== null" ref="ClosePayment" :otID="selectedOt.id" :budgets="budgets" @showAlert="successAction")
  VehicleDelivery(v-if="selectedOt !== null" ref="VehicleDelivery" :ot="selectedOt"  @showAlert="successAction" @success="fetchData")
  AssignTechModal(v-model="showAssignTechModal" :services="services" @showAlert="successAction")

  v-dialog(v-model="isApproveBudgetOpen" transition="dialog-top-transition" max-width="600")
    template(v-slot:default="dialog")
      v-card
        v-toolbar(color="#4C7AEC" dark) Seleccione el Presupuesto
        v-container
          v-select(:items="estimates" label="Presupuestos" outlined v-model="idBudgetSelected")
        v-card-actions.justify-end
          v-btn.mr-5(@click="handleOpenBudget()" color="#4C7AEC" dark :disabled="idBudgetSelected === null") Abrir
          v-btn(@click="isApproveBudgetOpen = false") Cerrar
.d-flex.justify-center(v-else)
  v-progress-circular.indeterminate(color="#4C7AEC" size="64")
</template>

<script>
import { io } from 'socket.io-client'
import settings from '@/../settings'
import HeadersCards from '@/components/OT/OTDetail/HeadersCards.vue'
import Budgets from '@/components/OT/OTDetail/Budgets.vue'
import History from '@/components/OT/OTDetail/History.vue'
import Services from '@/components/OT/OTDetail/Services.vue'
import Totals from '@/components/OT/OTDetail/Totals.vue'
import PartList from '@/components/OT/OTDetail/PartList.vue'
import ItemList from '@/components/OT/OTDetail/ItemList.vue'
import Expenses from '@/components/OT/OTDetail/Expenses.vue'
import Recommendations from '../components/OT/OTDetail/Recommendations.vue'
import { mapActions, mapGetters } from 'vuex'
import alert from '@/components/shared/alert.vue'
import OTDiagnosticReceptionistForm from '@/components/OT/OTDiagnosticReceptionistForm'
import ApproveBudget from '@/components/OT/ApproveBudget.vue'
import RejectBudget from '@/components/OT/RejectBudget.vue'
import ApproveWork from '@/components/OT/ApproveWork.vue'
import ClosePayment from '@/components/OT/ClosePayment.vue'
import VehicleDelivery from '@/components/OT/VehicleDelivery.vue'
import AssignTechModal from '@/components/OT/OTDetail/AssignTechModal.vue'

export default {
  components: {
    HeadersCards,
    Budgets,
    History,
    Services,
    Totals,
    PartList,
    ItemList,
    Expenses,
    Recommendations,
    alert,
    OTDiagnosticReceptionistForm,
    ApproveBudget,
    RejectBudget,
    ApproveWork,
    ClosePayment,
    VehicleDelivery,
    AssignTechModal,
  },
  data() {
    return {
      tab: null,
      tabs: [],
      selectedOt: {},
      budgets: [],
      vehicle_milage: null,
      haveMilage: true,
      services: [],
      idBudgetSelected: null,
      isApproveBudgetOpen: null,
      showAssignTechModal: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),

    workOrderActions() {
      return [
        {
          text: 'Completar presupuesto',
          color: '#FF897F',
          bgColor: '#FFE1DF',
          icon: 'mdi-square-edit-outline',
          action: () => this.handleOpenSelectDialog(this.selectedOt.id),
          condition: this.selectedOt.state === 'Diagnosticado',
        },
        {
          text: 'Aprobar presupuesto',
          color: '#2DA71A',
          bgColor: '#C0E5BB',
          icon: 'mdi-cash-check',
          action: () => this.openModalApprove(this.selectedOt.id),
          condition: this.selectedOt.state === 'Presupuestado',
        },
        {
          text: 'Rechazar presupuesto',
          color: '#FF897F',
          bgColor: '#F8F9FD',
          borderStyle: '1px solid #FF897F',
          icon: 'mdi-cancel',
          action: () => this.openModalRejectBudget(this.selectedOt.estimate),
          condition: this.selectedOt.estimate?.some(
            (e) => e.state === 'Presupuestado'
          ),
        },
        {
          text: 'Aprobar trabajo',
          color: '#FFFFFF',
          bgColor: '#462DFA',
          icon: 'mdi-check-circle-outline',
          action: () => this.openModalApproveWork(this.selectedOt.id),
          condition:
            this.selectedOt.state === 'Reparado' &&
            this.user?.groups.includes('Supervisor'),
        },
        {
          text: 'Saldo pendiente',
          color: '#FF4CD6',
          bgColor: '#FFD2F5',
          icon: 'mdi-account-cash',
          action: () => this.openModalClosePayment(this.selectedOt.id),
          condition:
            [
              'Listo',
              'Presupuesto aprobado',
              'En reparación',
              'Reparado',
            ].includes(this.selectedOt.state) &&
            this.selectedOt.pending_payment > 0 &&
            this.user?.groups[0] !== 'Supervisor',
        },
        {
          text: 'Entregar vehículo',
          color: '#407BFF',
          bgColor: '#ACC6FF',
          icon: 'mdi-car-side',
          action: () => this.openModalVehicleDelivery(),
          condition:
            this.selectedOt.state === 'Listo' &&
            this.selectedOt.pending_payment <= 0,
        },
        {
          text: 'Asignar técnico',
          color: '#337788',
          bgColor: '#99CCDD',
          icon: 'mdi-account-plus-outline',
          action: () => this.openAssignTechModal(),
          condition:
            this.selectedOt.state === 'Ingresado' || this.selectedOt.state === 'En Diagnóstico'
            || this.selectedOt.state === 'Borrador'
            || this.selectedOt.state === 'En reparación'

            // &&
            // this.selectedOt.estimate?.some((e) => e.requires_evaluation && e.evaluation)
            // ||
            // this.services?.[0]?.services?.length,
        },
      ]
    },

    actions() {
      return this.workOrderActions.filter((a) => a.condition)
    },
  },
  methods: {
    ...mapActions('inventory', ['getVehicleMilage', 'downloadOTPdf']),
    ...mapActions('estimate', ['listEstimates']),
    ...mapActions('ot', [
      'getOT',
      'listServices',
      'listOTPayments',
      'listOTTransitions',
      'listOT',
      'listBudgets',
      'approvedPayments',
      'servicesForAproval',
    ]),
    ...mapActions('service', ['listServicesInWorkOrder']),
    ...mapActions('user', ['listTechnicians']),
    async fetchData(id = this.$route.params.otID) {
      this.selectedOt = null
      this.loading = true
      if(id !== this.$route.params.otID) id = this.$route.params.otID
      this.getServices(id)
      this.getPayments(id)
      this.getTransitions(id)
      await this.getOT({ OTId: id }).then((response) => {
        this.selectedOt = response.data
        this.tabs = [
          {
            id: this.selectedOt.id,
            created_at: this.selectedOt.created_at,
          },
          ...this.selectedOt.previous_work_orders,
        ]
        this.tabs.sort((a, b) => {
          return b.id - a.id
        })
        this.tab = this.tabs.map((t) => t.id).indexOf(Number(this.$route.params.otID))
      })
      await this.listServicesInWorkOrder({ otID: this.$route.params.otID })
      this.services = []
      this.getVehicleMilage({ id: this.$route.params.otID }).then(
        (response) => {
          if (
            response.status === 200 &&
            response.data.vehicle_milage !== null
          ) {
            this.vehicle_milage = this.numberWithDots(
              response.data.vehicle_milage
            )
          } else {
            this.haveMilage = false
          }
        }
      )
      const estimates = this.selectedOt.estimate.filter(
        // (e) => e.state === 'Presupuesto aprobado' || e.state === 'En reparación'
        (e) => e.state !== 'Ingresado' && e.state !== 'En diagnóstico' && e.state !== 'Diagnosticado' && e.state !== 'Presupuestado'
      )
      if (estimates.length > 0) {
        this.listServicesInWorkOrder({ otID: this.selectedOt.id }).then(
          (response) => {
            if (response.status === 200) {
              this.services.push({
                otId: this.selectedOt.id,
                services: response.data.filter((service) =>
                  service
                ),
              })
            }
          }
        )
      }
      this.loading = false
    },
    handlerShowAlert(data) {
      this.$refs.alert.handleShowAlert(
        data.type,
        data.message,
        data.show,
        data.color
      )
      this.fetchData(this.$route.params.otID)
    },
    getServices(id) {
      const services = this.listServices({ OTId: id })
      return services
    },
    getPayments(id) {
      const payments = this.listOTPayments({ OTId: id })
      return payments
    },
    getTransitions(id) {
      const transitions = this.listOTTransitions({ OTId: id })
      return transitions
    },
    numberWithDots(n) {
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    async handleOpenSelectDialog(otId) {
      const res = await this.listEstimates({ params: { work_order: otId } })
      if (res.status < 200 || res.status >= 300) {
        this.$refs.alert.handleShowAlert(
          'error',
          'No se pudo obtener los presupuestos',
          true,
          'error'
        )
        return
      }

      const estimates = res.data.results
      if (estimates.length === 1 && estimates[0].state === 'Diagnosticado') {
        this.$refs.diagnosticReceptionistForm.open(estimates[0].id)
      } else {
        this.idBudgetSelected = null
        this.estimates = estimates.map((estimate) => {
          if (estimate.state === 'Diagnosticado') {
            return {
              text: estimate.name
                ? `N° ${estimate.id} - ${estimate.name}`
                : `Presupuesto N° ${estimate.id}`,
              value: estimate.id,
            }
          }
        })
        this.isApproveBudgetOpen = true
      }
    },
    async openModalApprove(id) {
      this.otID = id
      await this.listBudgets(id).then((response) => {
        if (response.status === 200) {
          this.budgets = response.data === '' ? [] : response.data
          this.$refs.alert.handleShowAlert('success', '', false, '')
          this.$refs.ApproveBudget.open()
        } else {
          this.$refs.alert.handleShowAlert(
            'error',
            'No hay presupuestos para mostrar.',
            true,
            'red'
          )
        }
      })
    },
    openModalRejectBudget(estimate) {
      this.$refs.alert.handleShowAlert('success', '', false, '')
      this.$refs.RejectBudget.open(estimate)
    },
    async openModalApproveWork(id) {
      this.otID = id
      await this.servicesForAproval({ otID: id }).then((response) => {
        if (response.status === 200) {
          this.$refs.ApproveWork.open()
        }
      })
    },
    successAction(data) {
      this.fetchData(this.$route.params.otID)
      this.$refs.alert.handleShowAlert(
        data.type,
        data.message,
        data.show,
        data.color
      )
    },
    handleOpenBudget() {
      this.isApproveBudgetOpen = false
      this.$refs.diagnosticReceptionistForm.open(this.idBudgetSelected)
    },
    openModalClosePayment(id) {
      this.otID = id
      this.approvedPayments({ otID: id }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          this.budgets = response.data === '' ? [] : response.data
          this.$refs.alert.handleShowAlert('success', '', false, '')
          this.$refs.ClosePayment.open()
        }
      })
    },
    openModalVehicleDelivery() {
      this.$refs.VehicleDelivery.open()
    },
    openAssignTechModal() {
      this.showAssignTechModal = true
    },
    async download(type) {
      await this.downloadOTPdf({
        id: this.$route.params.otID,
        type: type,
      }).then((response) => {
        const blob = new Blob([response.data], {
          type: {
            type: response.headers['content-type'],
          },
        })
        const blobUrl = window.URL.createObjectURL(blob)
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobUrl
        const nombreArchivo =
          type === 'recepcion'
            ? `Recepción OT ${this.$route.params.otID}.pdf`
            : `Entrega OT ${this.$route.params.otID}.pdf`
        tempLink.setAttribute('download', nombreArchivo)
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      })
    },
    formatDate(date) {
      return new Intl.DateTimeFormat('cl-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }).format(new Date(date))
    },
    goToDetailOt(id) {
      this.$router.push({ name: 'Detalle OT', params: { otID: id } })
      this.fetchData(id)
    },
    initWebsockets() {
      this.socket = io(settings.webSocketsUrl)

      this.socket.on('work_order:created', () => {
        this.fetchData()
      })

      this.socket.on('work_order:updated', () => {
        console.log('entro aca')
        this.fetchData()
      })
    },
  },
  async created() {
    await this.approvedPayments({ otID: this.$route.params.otID }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        this.budgets = response.data === '' ? [] : response.data
      }
    })
    this.fetchData(this.$route.params.otID)
    this.listTechnicians()
    this.initWebsockets()
  },
  destroyed() {
    if (!this.socket) return
    this.socket.disconnect()
  },
  async mounted () {
    this.tab = this.tabs.map((t) => t.id).indexOf(Number(this.$route.params.otID))
  }
}
</script>
<style scoped>
.otDataContainer {
  @media (max-width: 599px) {
    margin-bottom: 80px;
  }
}
.v-slide-group:not(.v-slide-group--has-affixes) > .v-slide-group__prev {
  display: flex !important;
}
.selectedOTtab {
  color: #3658d3 !important;
}
</style>
<style lang="scss" scoped>
.budgetTitle {
  color: #3658D3;
}
</style>
