import axios from '../../plugins/axios'

export const listServices = ({ commit }, { params } = {}) => {
  return axios.get('inventory/service/', { params })
    .then(res => {
      commit('SET_SERVICES', res.data)
      return res
    })
    .catch(err => err.response)
}

export const listServicesInWorkOrder = ({ commit }, { otID } = {}) => {
  return axios.get(`inventory/work_order/${otID}/get_order_services/`)
    .then(res => {
      commit('SET_SERVICES_IN_WORK_ORDER', res.data)
      return res
    })
    .catch(err => err.response)
}
export const listServicesInWorkOrder2 = ({ commit }, { otID } = {}) => {
  return axios.get(`inventory/work_order/${otID}/get_estimate_services/`)
    .then(res => {
      commit('SET_SERVICES_IN_WORK_ORDER', res.data)
      return res
    })
    .catch(err => err.response)
}

export const jobDone = (_, { data } = {}) => {
  return axios.post('/inventory/job_done', data)
    .then(res => res)
    .catch(err => err.response)
}
