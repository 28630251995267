<template lang="pug">
v-container(v-if="ot && estimate")
  v-row
    v-col(cols="2")
      div
        v-icon.ma-2(style="color: #1A1796;" dark @click="$router.back()") mdi-arrow-left
    v-col(cols="10")
      h2(style="color: #1A1796; font-weight: 600;") Trabajos {{ot.state === 'En reparación' ? ' en Curso' : ' Reparados' }}
  v-row
    v-col(cols="6")
      v-chip.ma-3(label text-color="#3264D1" :color="getStatusColor(ot.state)") Estado: {{ot.state}}
    v-col(cols="6")
      v-avatar.ma-3(color="#FFD699" size=32 dark v-for="tech in techniciansAssigned") {{ getAvatarText(tech)}}
  v-row
    v-expansion-panels(flat v-model="panel")
      v-expansion-panel(v-if="estimate.evaluation")
        v-expansion-panel-header.category Comentarios Diágnostico
        v-expansion-panel-content
          div.diagnostic-comments
            p {{ estimate.evaluation.evaluation_comment}}
      v-expansion-panel
        v-expansion-panel-header.category
            v-row(no-gutters)
              v-col(cols="6")
                p Kilometraje vehículo
              v-col.red--text(cols="6")
                span(v-if="vehicle_milage === null || vehicle_milage === ''" style="font-size: 12px;") Kilometraje no ingresado
        v-expansion-panel-content
          v-row(v-if="!editVehicleMilage")
            v-col(cols="8")
              v-text-field.mt-3(outlined dense type="number" style="width: 100%;" placeholder="Ingresa el kilometraje" v-model="vehicle_milage")
            v-col(cols="4")
              v-btn.mt-3(color="primary" outlined :disabled="vehicle_milage === null || vehicle_milage === ''" @click="handleRegisterMilage") Guardar
          v-row(v-else)
            v-col(cols="10")
              div.client-comments {{ vehicle_milage }} Kms
            v-col(cols="2")
              v-btn.mt-2(icon style="color: #171993" :disabled="vehicle_milage === null || vehicle_milage === ''" @click="editVehicleMilage = false")
                v-icon mdi-square-edit-outline
      v-expansion-panel
        v-expansion-panel-header.category Comentarios del Cliente
        v-expansion-panel-content
          div.client-comments
            p {{estimate.client_comments || 'Sin comentarios'}}
      v-expansion-panel
        v-expansion-panel-header.category Insumos Adicionales
        v-expansion-panel-content
          div
            AdditionalItems(v-if="!loading" :ot="ot" @showAlert="handleShowAlert" :disabled="ot.state === 'Reparado' || ot.state === 'Entregado' || ot.state === 'Listo'")
      v-expansion-panel
        v-expansion-panel-header.category Recomendaciones
        v-expansion-panel-content
          v-textarea(outlined background-color="#F6F5FD" v-model="recommendation" clearable counter :rules="rules" auto-grow rows="3" row-height="25" :disabled="ot.state === 'Reparado' || ot.state === 'Entregado' || ot.state === 'Listo'")
          v-btn.mb-2(block color="primary" @click="saveRecommendation" :loading="savingRecom" :disabled="recommendation === ''") Guardar
          v-card(v-for="r in recommendations" elevation="0" outlined :disabled="disabledDifferentTech(r)")
            v-row
              v-col(cols="8")
                v-card-subtitle.py-0.mt-3 Creado: {{ formatDate(r.date) }}
                p.ml-4 {{ r.text }}
              v-col(cols="2")
                v-avatar.mt-3(color="#FFD699" size=32 dark) {{getRecommendationAvatarText(r.added_by)}}
              v-col(cols="2")
                v-btn.mt-3(icon color="primary" @click="editRecommendation(r)")
                  v-icon mdi-pencil
  div(style="margin-top: 50px;")
  v-row(v-for="service in servicesInWorkOrder")
    WorkCardRepairDetail(:service="service" @showAlert="showAlert")
  alert(ref="alert")
  messageModal(ref="msg")
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import StateColorMixin from '../mixins/StateColorMixin.vue'
import VehicleDetailBox from '../vehicle/VehicleDetailBox.vue'
import WorkCardRepairDetail from './WorkInProgress/WorkCardRepairDetail.vue'
import alert from '../shared/alert.vue'
import PlusMinusField from '../shared/PlusMinusField.vue'
import messageModal from '../shared/messageModal.vue'
import AdditionalItems from './subcomponent/AdditionalItems.vue'

export default {
  mixins: [StateColorMixin],
  components: {
    VehicleDetailBox,
    WorkCardRepairDetail,
    alert,
    PlusMinusField,
    messageModal,
    AdditionalItems
  },
  data () {
    return {
      panel: -1,
      pieces: [],
      estimate: null,
      ot: null,
      recommendation: '',
      recommendations: [],
      selectedRecommendation: {},
      rules: [v => v.length <= 100 || 'Máx 100 caracteres'],
      isEditing: false,
      vehicle_milage: null,
      editVehicleMilage: false,
      savingRecom: false,
      loading: false
    }
  },
  methods: {
    ...mapActions('inventory', ['listPieces', 'getVehicleMilage', 'createVehicleMilage', 'getRecommendations', 'createRecommendation', 'updateRecommendation']),
    ...mapActions('estimate', ['getEstimate', 'setEstimateEvaluation']),
    ...mapActions('ot', ['getOT', 'setOTEvaluation', 'listEvaluation', 'listJobs', 'listJobsOrder']),
    ...mapActions('app', ['setTitle', 'setSubtitle', 'setBackgroundClass', 'setSubtitles']),
    ...mapActions('service', ['listServicesInWorkOrder']),
    ...mapActions('user', ['listTechnicians']),
    async fetchData () {
      this.loading = true
      this.recommendation = ''
      const otID = this.$route.params.otID
      const estimateID = this.$route.params.estimateID
      const estimateResponse = await this.getEstimate({ estimateId: estimateID })
      const otResponse = await this.getOT({ OTId: otID })
      this.estimate = estimateResponse.data
      this.ot = otResponse.data
      await this.listServicesInWorkOrder({ otID })
      this.listTechnicians()
      this.getAddicionalItems()
      this.getVehicleMilage({ id: otID }).then(
        (response) => {
          if (response.status === 200 && response.data.vehicle_milage !== null) {
            this.vehicle_milage = this.numberWithDots(response.data.vehicle_milage)
            this.editVehicleMilage = response.data.vehicle_milage !== null
          }
        }
      )
      this.getRecommendations({ id: this.ot.id }).then(
        (response) => {
          this.recommendations = response.data
        }
      )
      this.loading = false
    },
    getTechById (id) {
      return this.technicians.find(el => el.id === id)
    },
    getAvatarText (id) {
      const tech = this.getTechById(id)
      if (tech) {
        return tech.first_name.charAt(0) + tech.last_name.charAt(0)
      }
      return ''
    },
    getRecommendationAvatarText (tech) {
      return tech.first_name.charAt(0) + tech.last_name.charAt(0)
    },
    async showAlert (data) {
      await this.fetchData()
      if (this.ot.state === 'Reparado') {
        this.$refs.msg.isOpen = true
      } else {
        this.$refs.alert.handleShowAlert(data.type, data.message, data.show, data.color)
      }
    },
    async saveRecommendation () {
      if (this.recommendation !== '') {
        this.savingRecom = true
        const recommendationData = {
          text: this.recommendation,
          work_order: this.ot.id
        }
        if (this.isEditing) {
          await this.updateRecommendation({ id: this.selectedRecommendation.id, data: recommendationData }).then(
            (response) => {
              if (response.status >= 200 && response.status < 300) {
                this.showAlert({ type: 'sucess', message: 'Recomendación actualizada con éxito', show: true, color: 'green' })
              }
            }
          )
        } else {
          await this.createRecommendation({ data: recommendationData }).then(
            (response) => {
              if (response.status >= 200 && response.status < 300) {
                this.showAlert({ type: 'sucess', message: 'Recomendación creada con éxito', show: true, color: 'green' })
              }
            }
          )
        }
        this.savingRecom = false
        this.fetchData()
      }
    },
    editRecommendation (recommendation) {
      this.selectedRecommendation = recommendation
      this.recommendation = recommendation.text
      this.isEditing = true
    },
    disabledDifferentTech (recommendation) {
      if (this.user) {
        return recommendation.added_by.username !== this.user.username
      }
      return false
    },
    getAddicionalItems () {
      this.listPieces().then(
        (response) => {
          this.pieces = response.data.map(
            p => {
              const piece = this.ot.pieces.filter(q => q.piece === p.id)
              return {
                id: p.id,
                name: p.name,
                quantity: piece.length > 0 ? piece[0].quantity : 0
              }
            }
          )
        }
      )
    },
    handleShowAlert (data) {
      this.panel = -1
      window.scrollTo(1000, 0)
      this.fetchData()
      this.showAlert(data)
    },
    handleRegisterMilage () {
      const vehicleMilage = +this.vehicle_milage.split('.').join('')
      this.createVehicleMilage({ id: this.ot.id, data: { vehicle_milage: vehicleMilage } }).then(
        (response) => {
          if (response.status === 200) this.handleShowAlert({ type: 'success', message: 'Kilometraje guardado exitosamente', show: true, color: 'green' })
        }
      )
    },
    numberWithDots (n) {
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    formatDate (date) {
      return new Intl.DateTimeFormat('cl-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(date))
    }
  },
  created () {
    this.fetchData()
  },
  watch: {
    ot () {
      this.setTitle(`OT ${this.ot.id}`)
      if (!this.ot.vehicle_data) {
        return []
      }
      const subtitles = [
        this.ot.vehicle_data.model_display,
        `Patente: ${this.ot.vehicle_data.patent}`,
        `N° de Motor: ${this.ot.vehicle_data.engine_number}`,
        `N° de Chasis: ${this.ot.vehicle_data.chassis_number}`,
        `Combustión: ${this.ot.vehicle_data.fuel_type_display}`
      ]
      this.setSubtitles(subtitles)
      this.setBackgroundClass('carBackgroundImage')
    }
  },
  computed: {
    ...mapGetters('user', ['technicians']),
    ...mapGetters('service', ['servicesInWorkOrder']),
    ...mapGetters('auth', ['user']),
    techniciansAssigned () {
      if (this.servicesInWorkOrder.length > 0) {
        const technicians = this.servicesInWorkOrder.map((service) => service.assigned_to)
        return technicians.filter((tech, index) => technicians.indexOf(tech) === index)
      }
      return []
    }
  }
}
</script>
<style lang="scss" scoped>
.category {
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  color: #2657C1;
}
.client-comments {
  width: 100%;
  padding: 16px;
  background-color: #F6F5FD;
  font-size: 16px;
  color: #1A1796;
}
.diagnostic-comments {
  width: 100%;
  padding: 16px;
  background-color: #FFF4E1;
  font-size: 16px;
  color: #EA5C0B;
}
.v-data-table::v-deep th {
  color: #1A1796 !important;
  font-size: 16px !important;
  text-align: center !important;
  padding: 20px 0 !important;
}
.v-data-table::v-deep td {
  color: #2657C1;
  font-size: 16px !important;
  height: 50px !important;
}

</style>
